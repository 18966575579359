<script setup lang="ts">
defineSlice({
  name: { label: 'Bento Code Illustration', group: 'Bento', layouts: ['VoixMarketing'] },
  description: '',
  preview: 'SlicesBentoCodeAnimation.jpg',
  fields: {

  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const activated = inject('activated')
</script>

<template>
  <div class="absolute inset-0 overflow-hidden">
    <svg
      width="1280.000000pt" height="904.000000pt" viewBox="0 0 1280.000000 904.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <pattern id="codePattern" width="257" height="63" patternUnits="userSpaceOnUse">
        <g transform="scale(0.2)">
          <g
            transform="translate(0,904) scale(0.1,-0.1)"
            fill="#fff" stroke="none"
          >
            <path
              d="M313 8880 c-43 -17 -63 -67 -63 -156 0 -68 4 -87 23 -115 28 -41 74
            -57 116 -39 44 18 63 55 69 132 6 92 -16 154 -64 174 -38 16 -50 16 -81 4z
            m83 -51 c15 -17 19 -37 19 -104 0 -94 -16 -125 -66 -125 -40 0 -59 41 -59 125
            0 84 19 125 59 125 16 0 36 -9 47 -21z"
            />
            <path
              d="M1124 8871 c-40 -24 -54 -65 -54 -156 0 -58 4 -78 23 -106 28 -41 74
            -57 116 -39 44 18 63 55 69 132 6 92 -16 154 -64 174 -43 18 -53 18 -90 -5z
            m92 -42 c15 -17 19 -37 19 -104 0 -95 -16 -125 -67 -125 -24 0 -32 6 -44 35
            -26 62 -14 186 19 207 22 14 54 8 73 -13z"
            />
            <path
              d="M1404 8876 c-85 -38 -86 -259 -2 -302 54 -28 106 -7 138 55 30 58 10
            204 -33 239 -23 19 -70 23 -103 8z m91 -56 c9 -17 15 -55 15 -95 0 -84 -19
            -125 -59 -125 -49 0 -66 31 -66 119 0 93 19 131 65 131 23 0 33 -7 45 -30z"
            />
            <path
              d="M1685 8881 c-35 -15 -55 -46 -67 -103 -29 -139 49 -251 145 -207 74
            34 91 225 24 287 -26 25 -73 35 -102 23z m82 -58 c12 -19 17 -47 17 -98 0 -88
            -19 -125 -64 -125 -48 0 -63 34 -59 133 4 94 16 117 60 117 20 0 34 -8 46 -27z"
            />
            <path
              d="M1944 8871 c-42 -26 -57 -74 -52 -166 4 -85 26 -124 77 -139 45 -13
            98 13 117 59 19 47 18 158 -2 201 -17 35 -58 64 -91 64 -10 0 -32 -9 -49 -19z
            m91 -41 c21 -23 31 -108 21 -164 -9 -45 -30 -66 -67 -66 -25 0 -33 6 -45 35
            -26 62 -14 186 19 207 22 14 54 8 72 -12z"
            />
            <path
              d="M2764 8871 c-42 -26 -57 -74 -52 -166 4 -87 26 -125 80 -139 78 -20
            128 41 128 157 0 81 -20 131 -61 152 -37 20 -58 19 -95 -4z m91 -41 c21 -23
            31 -108 21 -164 -9 -45 -30 -66 -67 -66 -25 0 -33 6 -46 37 -21 51 -15 164 11
            192 24 27 58 27 81 1z"
            />
            <path
              d="M3584 8871 c-42 -26 -57 -74 -52 -166 4 -87 26 -125 80 -139 78 -20
            128 41 128 157 0 83 -19 131 -59 151 -39 21 -59 20 -97 -3z m83 -29 c33 -21
            45 -145 19 -207 -12 -29 -20 -35 -45 -35 -55 0 -78 50 -69 155 6 77 49 116 95
            87z"
            />
            <path
              d="M3871 8877 c-49 -25 -66 -62 -66 -145 0 -43 6 -91 14 -110 27 -63
            112 -79 159 -29 27 29 45 110 37 168 -14 99 -77 151 -144 116z m86 -57 c7 -14
            13 -56 13 -95 0 -88 -15 -119 -57 -123 -48 -5 -67 32 -67 123 0 91 19 128 67
            123 22 -2 35 -11 44 -28z"
            />
            <path
              d="M4143 8880 c-48 -19 -77 -105 -66 -193 11 -94 75 -145 144 -117 94
            39 91 276 -3 309 -36 13 -45 13 -75 1z m84 -57 c25 -38 25 -158 0 -197 -24
            -36 -75 -34 -94 4 -18 35 -16 154 2 190 21 41 67 42 92 3z"
            />
            <path
              d="M5781 8877 c-43 -21 -62 -67 -62 -151 0 -102 26 -150 86 -162 74 -14
            125 52 125 161 0 77 -25 134 -66 151 -41 17 -52 17 -83 1z m85 -48 c15 -17 19
            -37 19 -104 0 -94 -16 -125 -66 -125 -40 0 -59 41 -59 125 0 84 19 125 59 125
            16 0 36 -9 47 -21z"
            />
            <path
              d="M6600 8877 c-40 -20 -60 -72 -60 -158 0 -62 4 -82 23 -110 28 -41 74
            -57 116 -39 44 18 63 55 69 132 6 92 -16 154 -64 174 -41 17 -52 17 -84 1z
            m86 -48 c15 -17 19 -37 19 -104 0 -95 -16 -125 -67 -125 -24 0 -32 6 -44 35
            -26 62 -14 186 19 207 22 14 54 8 73 -13z"
            />
            <path
              d="M7694 8876 c-88 -39 -84 -269 5 -306 69 -28 133 23 144 117 11 91
            -18 174 -69 193 -32 13 -42 12 -80 -4z m91 -56 c18 -36 20 -155 2 -190 -19
            -38 -70 -40 -94 -4 -25 39 -25 159 0 197 25 39 71 38 92 -3z"
            />
            <path
              d="M8521 8877 c-65 -33 -92 -150 -56 -243 29 -75 111 -96 163 -41 27 29
            45 110 37 168 -14 99 -77 151 -144 116z m86 -57 c7 -14 13 -56 13 -95 0 -88
            -15 -119 -57 -123 -48 -5 -68 32 -68 123 0 91 20 128 68 123 22 -2 35 -11 44
            -28z"
            />
            <path
              d="M8793 8880 c-74 -31 -94 -220 -31 -287 44 -47 125 -37 155 20 20 40
            28 132 14 183 -18 69 -82 108 -138 84z m84 -57 c25 -38 25 -158 0 -197 -24
            -36 -75 -34 -95 4 -17 33 -12 181 7 203 22 27 68 21 88 -10z"
            />
            <path
              d="M9054 8871 c-42 -26 -57 -74 -52 -166 4 -87 26 -125 80 -139 78 -20
            128 41 128 157 0 83 -19 131 -59 151 -39 21 -59 20 -97 -3z m83 -29 c33 -21
            45 -145 19 -207 -12 -29 -20 -35 -45 -35 -55 0 -78 50 -69 155 6 77 49 116 95
            87z"
            />
            <path
              d="M9613 8880 c-74 -31 -94 -220 -31 -287 29 -31 78 -39 116 -19 75 39
            85 226 15 288 -24 22 -71 30 -100 18z m84 -57 c25 -38 25 -158 0 -197 -24 -36
            -75 -34 -94 4 -18 35 -16 154 2 190 21 41 67 42 92 3z"
            />
            <path
              d="M10161 8878 c-50 -27 -66 -63 -66 -151 0 -90 18 -136 62 -156 97 -44
            176 72 144 212 -18 83 -81 126 -140 95z m86 -58 c19 -37 17 -170 -3 -198 -22
            -32 -69 -30 -91 5 -25 37 -24 159 0 197 24 36 75 34 94 -4z"
            />
            <path
              d="M10433 8880 c-48 -19 -77 -105 -66 -193 11 -94 75 -145 144 -117 91
            38 93 268 3 306 -38 16 -50 16 -81 4z m90 -70 c14 -27 17 -51 14 -103 -5 -77
            -24 -107 -68 -107 -40 0 -59 41 -59 125 0 89 20 127 63 123 25 -2 36 -11 50
            -38z"
            />
            <path
              d="M10705 8876 c-16 -8 -36 -24 -44 -37 -22 -37 -27 -166 -8 -211 31
            -74 124 -88 171 -25 15 21 22 47 24 102 5 92 -10 140 -52 166 -36 22 -50 23
            -91 5z m72 -34 c34 -21 45 -159 18 -212 -19 -37 -62 -41 -91 -9 -15 17 -19 37
            -19 104 0 67 4 87 19 104 19 21 51 27 73 13z"
            />
            <path
              d="M10981 8878 c-41 -22 -62 -61 -68 -124 -7 -78 10 -136 48 -168 43
            -36 83 -34 124 7 40 40 54 117 35 196 -18 78 -82 119 -139 89z m85 -53 c23
            -35 23 -164 0 -199 -23 -36 -69 -36 -93 1 -24 36 -23 161 1 197 22 35 69 36
            92 1z"
            />
            <path
              d="M11251 8877 c-43 -21 -62 -67 -62 -151 0 -102 26 -150 86 -162 74
            -14 125 52 125 161 0 77 -25 134 -66 151 -41 17 -52 17 -83 1z m85 -48 c15
            -17 19 -37 19 -104 0 -94 -16 -125 -66 -125 -40 0 -59 41 -59 125 0 84 19 125
            59 125 16 0 36 -9 47 -21z"
            />
            <path
              d="M11524 8876 c-45 -20 -68 -85 -62 -174 6 -77 25 -114 69 -132 42 -18
            88 -2 116 39 40 59 28 219 -19 258 -24 20 -70 24 -104 9z m91 -56 c9 -17 15
            -55 15 -95 0 -84 -19 -125 -59 -125 -49 0 -66 31 -66 119 0 93 19 131 65 131
            23 0 33 -7 45 -30z"
            />
            <path
              d="M11804 8881 c-55 -23 -83 -109 -68 -207 9 -63 56 -114 104 -114 45 0
            95 52 105 109 12 70 -3 148 -33 182 -29 31 -74 44 -108 30z m82 -57 c12 -18
            17 -47 17 -99 0 -89 -18 -125 -63 -125 -45 0 -62 34 -62 125 0 91 17 125 62
            125 21 0 35 -8 46 -26z"
            />
            <path
              d="M18 8873 c-10 -2 -18 -13 -18 -24 0 -17 5 -19 35 -13 l36 6 -3 -118
            -3 -119 -33 -3 c-20 -2 -32 -9 -32 -18 0 -11 18 -14 90 -14 73 0 90 3 90 15 0
            10 -11 15 -35 15 l-35 0 0 140 0 140 -37 -1 c-21 -1 -46 -3 -55 -6z"
            />
            <path
              d="M563 8873 c-15 -3 -23 -12 -23 -24 0 -17 5 -19 35 -13 l35 6 0 -121
            0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 72 0 90 3 90 14 0 9
            -12 16 -32 18 l-33 3 0 138 0 137 -35 -1 c-19 -1 -45 -4 -57 -6z"
            />
            <path
              d="M838 8873 c-20 -3 -28 -10 -28 -25 0 -18 4 -19 40 -13 l41 7 -3 -119
            -3 -118 -32 -3 c-21 -2 -33 -9 -33 -18 0 -11 18 -14 90 -14 73 0 90 3 90 15 0
            10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M2203 8873 c-15 -3 -23 -12 -23 -25 0 -18 4 -19 35 -14 l35 7 0 -121
            0 -120 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 93 -15 77 0 94 3 94 15 0 10
            -11 15 -34 15 l-33 0 0 140 0 140 -37 -1 c-21 -1 -48 -4 -60 -6z"
            />
            <path
              d="M2478 8873 c-20 -3 -28 -10 -28 -25 0 -18 4 -19 40 -13 l41 7 -3
            -119 -3 -118 -32 -3 c-21 -2 -33 -9 -33 -18 0 -11 18 -14 90 -14 73 0 90 3 90
            15 0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M3023 8873 c-15 -3 -23 -12 -23 -25 0 -18 4 -19 35 -14 l35 7 0 -121
            0 -120 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 95 -15 78 0 95 3 95 15 0 10
            -11 15 -35 15 l-35 0 0 140 0 140 -37 -1 c-21 -1 -48 -4 -60 -6z"
            />
            <path
              d="M3298 8873 c-20 -3 -28 -10 -28 -25 0 -18 4 -19 40 -13 l40 7 0 -121
            0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15 0 10
            -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M4393 8873 c-15 -3 -23 -12 -23 -24 0 -17 5 -19 35 -13 l35 6 0 -121
            0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 72 0 90 3 90 14 0 9
            -12 16 -32 18 l-33 3 -3 138 -3 137 -32 -1 c-18 -1 -42 -4 -54 -6z"
            />
            <path
              d="M4663 8873 c-15 -3 -23 -12 -23 -25 0 -18 4 -19 40 -13 l41 7 -3
            -119 -3 -118 -32 -3 c-21 -2 -33 -9 -33 -18 0 -11 18 -14 90 -14 73 0 90 3 90
            15 0 10 -11 15 -35 15 l-35 0 0 140 0 140 -37 -1 c-21 -1 -48 -4 -60 -6z"
            />
            <path
              d="M4940 8871 c-14 -4 -26 -15 -28 -25 -3 -16 1 -17 37 -11 l41 7 0
            -121 0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15
            0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -44 -4 -58 -8z"
            />
            <path
              d="M5213 8873 c-15 -3 -23 -12 -23 -24 0 -17 5 -19 35 -13 l35 6 0 -121
            0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 72 0 90 3 90 14 0 9
            -12 16 -32 18 l-33 3 -3 138 -3 137 -32 -1 c-18 -1 -42 -4 -54 -6z"
            />
            <path
              d="M5483 8873 c-15 -3 -23 -12 -23 -25 0 -18 4 -19 40 -13 l41 7 -3
            -119 -3 -118 -32 -3 c-21 -2 -33 -9 -33 -18 0 -11 18 -14 90 -14 73 0 90 3 90
            15 0 10 -11 15 -35 15 l-35 0 0 140 0 140 -37 -1 c-21 -1 -48 -4 -60 -6z"
            />
            <path
              d="M6033 8873 c-15 -3 -23 -12 -23 -24 0 -17 5 -19 35 -13 l35 6 0 -121
            0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 72 0 90 3 90 14 0 9
            -12 16 -32 18 l-33 3 0 138 0 137 -35 -1 c-19 -1 -45 -4 -57 -6z"
            />
            <path
              d="M6308 8873 c-20 -3 -28 -10 -28 -25 0 -18 4 -19 40 -13 l41 7 -3
            -119 -3 -118 -32 -3 c-21 -2 -33 -9 -33 -18 0 -11 18 -14 90 -14 73 0 90 3 90
            15 0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M6853 8873 c-15 -3 -23 -12 -23 -24 0 -17 5 -19 35 -13 l35 6 0 -121
            0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 72 0 90 3 90 14 0 9
            -12 16 -31 18 l-31 3 1 138 2 137 -38 -1 c-21 -1 -48 -4 -60 -6z"
            />
            <path
              d="M7128 8873 c-20 -3 -28 -10 -28 -25 0 -18 4 -19 40 -13 l41 7 -3
            -119 -3 -118 -32 -3 c-21 -2 -33 -9 -33 -18 0 -11 18 -14 90 -14 73 0 90 3 90
            15 0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M7400 8871 c-14 -4 -26 -15 -28 -25 -3 -16 1 -17 37 -11 l41 7 0
            -121 0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15
            0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -44 -4 -58 -8z"
            />
            <path
              d="M7948 8873 c-20 -3 -28 -10 -28 -25 0 -18 4 -19 40 -13 l41 7 -3
            -119 -3 -118 -32 -3 c-21 -2 -33 -9 -33 -18 0 -11 18 -14 90 -14 73 0 90 3 90
            15 0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M8224 8873 c-12 -2 -24 -13 -27 -24 -5 -19 -2 -20 34 -14 l39 7 0
            -121 0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15
            0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -43 -4 -54 -6z"
            />
            <path
              d="M9313 8873 c-15 -3 -23 -12 -23 -25 0 -17 4 -19 38 -13 l37 6 -2
            -118 -2 -118 -29 -3 c-17 -2 -33 -10 -36 -18 -4 -12 11 -14 89 -14 78 0 95 3
            95 15 0 10 -11 15 -35 15 l-35 0 0 140 0 140 -37 -1 c-21 -1 -48 -4 -60 -6z"
            />
            <path
              d="M9863 8873 c-15 -3 -23 -12 -23 -24 0 -17 5 -19 35 -13 l35 6 0 -121
            0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 72 0 90 3 90 14 0 9
            -12 16 -32 18 l-33 3 -3 138 -3 137 -32 -1 c-18 -1 -42 -4 -54 -6z"
            />
            <path
              d="M12050 8871 c-14 -4 -26 -15 -28 -25 -3 -16 1 -17 37 -11 l41 7 0
            -121 0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15
            0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -44 -4 -58 -8z"
            />
            <path
              d="M12323 8873 c-15 -3 -23 -12 -23 -24 0 -17 5 -19 35 -13 l35 6 0
            -121 0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 72 0 90 3 90 14
            0 9 -12 16 -32 18 l-33 3 0 137 0 138 -35 -1 c-19 -1 -45 -4 -57 -6z"
            />
            <path
              d="M12598 8873 c-20 -3 -28 -10 -28 -25 0 -18 4 -19 40 -13 l41 7 -3
            -119 -3 -118 -32 -3 c-21 -2 -33 -9 -33 -18 0 -11 18 -14 90 -14 73 0 90 3 90
            15 0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M26 8339 c-26 -20 -26 -22 -26 -144 0 -117 1 -125 22 -139 27 -19 96
            -20 120 -2 45 33 64 169 34 240 -27 65 -98 86 -150 45z m93 -28 c25 -25 35
            -78 28 -148 -6 -61 -28 -93 -62 -93 -38 0 -59 35 -63 105 -5 77 2 109 28 135
            25 25 45 25 69 1z"
            />
            <path
              d="M297 8340 c-31 -24 -47 -75 -47 -147 0 -97 38 -153 102 -153 22 0 48
            5 59 10 42 23 65 117 48 205 -16 91 -99 134 -162 85z m94 -29 c20 -16 24 -30
            27 -95 3 -47 -1 -88 -8 -106 -14 -33 -53 -49 -79 -33 -26 16 -43 82 -38 144 6
            74 23 100 68 108 4 0 17 -8 30 -18z"
            />
            <path
              d="M584 8349 c-40 -20 -58 -68 -58 -149 0 -116 31 -160 112 -160 32 0
            45 6 66 30 40 48 47 162 14 234 -21 47 -85 68 -134 45z m73 -34 c30 -21 47
            -87 38 -150 -8 -61 -28 -95 -58 -95 -49 0 -67 36 -67 135 0 67 3 79 25 100 28
            29 33 30 62 10z"
            />
            <path
              d="M1127 8349 c-66 -39 -76 -221 -15 -286 23 -25 83 -32 119 -13 69 38
            74 228 7 285 -31 27 -79 33 -111 14z m84 -38 c19 -16 24 -30 27 -90 6 -108
            -12 -151 -63 -151 -67 0 -89 177 -30 236 29 29 36 29 66 5z"
            />
            <path
              d="M1952 8350 c-70 -28 -83 -220 -20 -287 23 -25 83 -32 119 -13 70 38
            75 228 8 285 -31 26 -67 31 -107 15z m83 -45 c22 -21 25 -33 25 -100 0 -99
            -18 -135 -67 -135 -67 0 -84 200 -20 245 29 20 34 19 62 -10z"
            />
            <path
              d="M2212 8335 c-67 -57 -62 -247 7 -285 36 -19 96 -12 119 13 62 66 51
            250 -16 286 -35 18 -79 13 -110 -14z m97 -29 c43 -46 36 -200 -10 -229 -26
            -16 -65 0 -79 33 -7 18 -11 59 -8 106 3 65 7 79 27 95 30 25 43 24 70 -5z"
            />
            <path
              d="M2491 8344 c-34 -24 -51 -74 -51 -149 0 -46 6 -78 20 -104 22 -44 35
            -51 91 -51 68 0 99 48 99 156 0 106 -37 164 -105 164 -17 0 -41 -7 -54 -16z
            m88 -33 c38 -38 42 -181 6 -221 -43 -48 -93 -12 -102 73 -10 95 17 167 62 167
            8 0 24 -9 34 -19z"
            />
            <path
              d="M2769 8345 c-66 -36 -80 -206 -23 -275 21 -24 34 -30 66 -30 51 0 74
            13 95 53 25 46 24 171 -1 214 -31 51 -85 66 -137 38z m86 -40 c22 -21 25 -33
            25 -100 0 -99 -18 -135 -67 -135 -64 0 -84 180 -27 237 29 29 39 29 69 -2z"
            />
            <path
              d="M3370 8353 c-77 -10 -90 -15 -90 -33 0 -16 5 -18 35 -13 l35 6 0
            -117 0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20
            0 16 -7 20 -35 20 l-34 0 -3 138 c-3 115 -5 137 -18 135z"
            />
            <path
              d="M3589 8345 c-66 -36 -80 -206 -23 -275 34 -40 102 -43 139 -6 67 67
            53 251 -22 285 -32 15 -62 13 -94 -4z m81 -33 c28 -23 40 -92 29 -165 -9 -55
            -28 -77 -66 -77 -64 0 -84 179 -27 237 28 28 35 28 64 5z"
            />
            <path
              d="M4409 8345 c-67 -37 -78 -216 -17 -282 28 -30 95 -31 131 -3 46 36
            60 168 26 244 -23 50 -88 69 -140 41z m81 -33 c40 -33 48 -164 14 -219 -16
            -26 -65 -31 -82 -10 -38 45 -35 185 4 224 28 28 35 28 64 5z"
            />
            <path
              d="M4682 8344 c-37 -26 -52 -67 -52 -144 0 -117 29 -160 108 -160 66 0
            102 56 102 161 0 101 -38 159 -105 159 -17 0 -41 -7 -53 -16z m87 -33 c37 -37
            40 -165 7 -216 -20 -31 -56 -32 -82 -4 -16 17 -19 37 -19 111 0 82 2 91 24
            109 29 24 46 24 70 0z"
            />
            <path
              d="M4947 8340 c-31 -24 -47 -75 -47 -147 0 -97 38 -153 102 -153 53 0
            77 15 95 57 19 47 19 163 -1 202 -32 61 -100 80 -149 41z m94 -29 c21 -17 24
            -29 27 -103 3 -73 1 -86 -18 -110 -28 -36 -64 -37 -87 -2 -37 57 -24 206 20
            224 31 12 32 12 58 -9z"
            />
            <path
              d="M6597 8349 c-66 -39 -76 -221 -15 -286 23 -25 83 -32 119 -13 69 38
            74 228 7 285 -31 27 -79 33 -111 14z m84 -38 c20 -16 24 -30 27 -95 3 -47 -1
            -88 -8 -106 -14 -33 -53 -49 -79 -33 -49 30 -53 189 -6 231 32 27 36 27 66 3z"
            />
            <path
              d="M7422 8350 c-70 -28 -83 -220 -20 -287 23 -25 83 -32 119 -13 70 38
            75 228 8 285 -31 26 -67 31 -107 15z m83 -45 c22 -21 25 -33 25 -100 0 -99
            -18 -135 -67 -135 -67 0 -84 200 -20 245 29 20 34 19 62 -10z"
            />
            <path
              d="M8500 8333 c-65 -60 -59 -246 9 -283 11 -5 37 -10 59 -10 66 0 102
            57 102 158 -1 137 -90 207 -170 135z m99 -24 c37 -40 40 -164 5 -216 -27 -42
            -86 -23 -100 33 -3 13 -4 56 -2 95 3 60 8 74 27 90 30 25 46 24 70 -2z"
            />
            <path
              d="M8840 8353 c-77 -10 -90 -15 -90 -33 0 -16 5 -18 35 -13 l35 6 0
            -117 0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20
            0 16 -7 20 -35 20 l-34 0 -3 138 c-3 115 -5 137 -18 135z"
            />
            <path
              d="M9320 8333 c-36 -33 -52 -109 -41 -183 13 -83 40 -110 109 -110 43 0
            73 24 91 73 14 36 14 121 1 169 -22 77 -103 102 -160 51z m90 -13 c38 -20 53
            -141 25 -205 -16 -39 -34 -50 -69 -41 -35 9 -49 53 -44 142 3 65 7 79 27 95
            27 22 35 23 61 9z"
            />
            <path
              d="M9601 8344 c-33 -24 -51 -74 -51 -144 0 -65 19 -125 48 -146 21 -16
            86 -18 114 -3 30 16 48 71 48 149 0 77 -15 118 -52 144 -29 21 -77 20 -107 0z
            m90 -33 c22 -18 24 -27 24 -109 0 -74 -3 -94 -19 -111 -42 -46 -92 -13 -103
            68 -12 85 19 171 61 171 8 0 24 -9 37 -19z"
            />
            <path
              d="M9879 8345 c-67 -37 -78 -216 -17 -282 28 -30 95 -31 131 -3 46 36
            60 168 26 244 -23 50 -88 69 -140 41z m81 -33 c42 -34 48 -184 9 -223 -26 -26
            -65 -24 -83 4 -34 55 -26 186 14 219 28 22 32 22 60 0z"
            />
            <path
              d="M10421 8344 c-33 -23 -51 -74 -51 -143 0 -105 36 -161 102 -161 52 0
            77 15 94 55 19 46 18 168 -1 205 -30 58 -95 78 -144 44z m90 -33 c22 -18 24
            -27 24 -109 0 -74 -3 -94 -19 -111 -26 -29 -62 -27 -83 5 -37 57 -24 206 20
            224 31 12 32 12 58 -9z"
            />
            <path
              d="M10972 8344 c-37 -26 -52 -67 -52 -144 0 -78 18 -133 48 -149 28 -15
            93 -13 114 3 45 33 64 169 34 240 -26 61 -94 85 -144 50z m87 -33 c25 -25 35
            -78 28 -148 -6 -61 -28 -93 -62 -93 -38 0 -59 35 -63 105 -5 77 2 109 28 135
            25 25 45 25 69 1z"
            />
            <path
              d="M11237 8340 c-31 -24 -47 -75 -47 -147 0 -97 38 -153 102 -153 22 0
            48 5 59 10 42 23 65 117 48 205 -16 91 -99 134 -162 85z m94 -29 c20 -16 24
            -30 27 -95 3 -47 -1 -88 -8 -106 -14 -33 -53 -49 -79 -33 -26 16 -43 82 -38
            144 6 74 23 100 68 108 4 0 17 -8 30 -18z"
            />
            <path
              d="M11524 8349 c-72 -35 -84 -220 -19 -285 37 -37 105 -34 139 6 40 48
            47 162 14 234 -21 47 -85 68 -134 45z m73 -34 c30 -21 47 -87 38 -150 -8 -61
            -28 -95 -58 -95 -49 0 -67 36 -67 135 0 67 3 79 25 100 28 29 33 30 62 10z"
            />
            <path
              d="M11791 8344 c-34 -24 -51 -74 -51 -148 0 -110 31 -156 103 -156 23 0
            50 7 59 14 29 21 48 81 48 146 0 102 -38 160 -105 160 -17 0 -41 -7 -54 -16z
            m88 -33 c25 -25 35 -78 28 -148 -9 -85 -59 -121 -102 -73 -21 23 -31 108 -21
            165 8 39 37 75 61 75 8 0 24 -9 34 -19z"
            />
            <path
              d="M12067 8349 c-66 -39 -76 -221 -15 -286 23 -25 83 -32 119 -13 69 38
            74 228 7 285 -31 27 -79 33 -111 14z m84 -38 c20 -16 24 -30 27 -95 3 -47 -1
            -88 -8 -106 -14 -33 -53 -49 -79 -33 -49 30 -53 189 -6 231 32 27 36 27 66 3z"
            />
            <path
              d="M12343 8349 c-76 -39 -78 -259 -4 -299 36 -19 96 -12 119 13 54 57
            56 203 4 267 -20 25 -86 35 -119 19z m74 -34 c30 -21 47 -87 38 -150 -8 -61
            -28 -95 -58 -95 -49 0 -67 36 -67 135 0 67 3 79 25 100 28 29 33 30 62 10z"
            />
            <path
              d="M12611 8344 c-34 -24 -51 -74 -51 -148 0 -111 30 -156 105 -156 75 0
            105 45 105 156 0 106 -37 164 -105 164 -17 0 -41 -7 -54 -16z m88 -33 c38 -38
            42 -181 6 -221 -44 -48 -96 -10 -103 75 -8 95 19 165 63 165 8 0 24 -9 34 -19z"
            />
            <path
              d="M825 8337 c-27 -20 -10 -37 30 -30 l35 6 0 -117 0 -116 -35 0 c-28 0
            -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16 -7 20 -35 20 l-35 0
            0 135 0 135 -43 0 c-24 0 -51 -6 -62 -13z"
            />
            <path
              d="M1378 8343 c-10 -2 -18 -13 -18 -23 0 -15 6 -17 40 -13 l40 6 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M1653 8343 c-7 -2 -13 -13 -13 -23 0 -16 6 -18 35 -13 l35 6 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 0 -49 -3 -55 -6z"
            />
            <path
              d="M3018 8343 c-10 -2 -18 -13 -18 -23 0 -15 6 -17 40 -13 l40 6 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M3838 8343 c-10 -2 -18 -13 -18 -23 0 -15 6 -17 40 -13 l40 6 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M4118 8343 c-10 -2 -18 -13 -18 -24 0 -10 1 -19 3 -19 1 0 17 4 35 9
            l32 10 0 -120 0 -119 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90
            1 90 20 0 16 -7 20 -35 20 l-35 0 0 135 0 135 -37 -1 c-21 -1 -46 -3 -55 -6z"
            />
            <path
              d="M5208 8343 c-10 -2 -18 -13 -18 -23 0 -15 6 -17 40 -13 l40 6 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M5478 8343 c-10 -2 -18 -13 -18 -24 0 -17 5 -18 40 -13 l40 7 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M5758 8343 c-10 -2 -18 -13 -18 -23 0 -16 5 -18 35 -13 l35 6 0 -116
            0 -116 -32 -3 c-22 -2 -34 -9 -36 -20 -3 -16 6 -18 87 -18 84 0 91 1 91 20 0
            16 -7 20 -32 20 l-33 0 3 135 3 135 -43 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M6028 8343 c-10 -2 -18 -13 -18 -23 0 -15 6 -17 40 -13 l40 6 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M6295 8337 c-27 -20 -10 -37 30 -30 l35 6 0 -117 0 -116 -35 0 c-28
            0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16 -7 20 -35 20 l-35
            0 0 135 0 135 -43 0 c-24 0 -51 -6 -62 -13z"
            />
            <path
              d="M6848 8343 c-10 -2 -18 -13 -18 -23 0 -15 6 -17 40 -13 l40 6 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M7123 8343 c-7 -2 -13 -13 -13 -23 0 -16 6 -18 35 -13 l35 6 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 0 -49 -3 -55 -6z"
            />
            <path
              d="M7668 8343 c-10 -2 -18 -13 -18 -23 0 -15 6 -17 40 -13 l40 6 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M7943 8343 c-11 -4 -20 -43 -10 -43 1 0 17 4 35 9 l32 10 0 -120 0
            -119 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16 -7
            20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 0 -49 -3 -55 -6z"
            />
            <path
              d="M8218 8343 c-10 -2 -18 -13 -18 -23 0 -15 6 -17 38 -13 l37 5 0 -116
            0 -116 -32 0 c-26 0 -33 -4 -33 -20 0 -19 7 -20 91 -20 81 0 90 2 87 18 -2 11
            -14 18 -36 20 l-32 3 0 135 0 134 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M9038 8343 c-10 -2 -18 -13 -18 -23 0 -15 6 -17 40 -13 l40 6 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 91 -20 81 0 90 2 87 18 -2 11
            -14 18 -36 20 l-32 3 0 135 0 134 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M10128 8343 c-10 -2 -18 -13 -18 -24 0 -17 5 -18 40 -13 l40 7 0
            -117 0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20
            0 16 -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M10678 8343 c-10 -2 -18 -13 -18 -23 0 -15 6 -17 40 -13 l40 6 0
            -117 0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20
            0 16 -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M65 7825 c-5 -2 -22 -6 -37 -9 -17 -4 -28 -13 -28 -23 0 -13 8 -15
            40 -10 l40 5 0 -119 0 -119 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20
            83 0 90 1 90 20 0 16 -7 20 -35 20 l-35 0 0 140 c0 139 0 140 -22 139 -13 0
            -27 -2 -33 -4z"
            />
            <path
              d="M315 7821 c-21 -4 -31 -13 -33 -28 -3 -20 -1 -21 30 -12 18 5 34 9
            35 9 2 0 3 -54 3 -120 l0 -120 -36 0 c-28 0 -35 -3 -32 -17 3 -15 17 -18 91
            -21 82 -3 87 -2 87 18 0 16 -6 20 -30 20 l-29 0 -3 138 -3 137 -25 2 c-14 0
            -38 -2 -55 -6z"
            />
            <path
              d="M587 7819 c-34 -20 -51 -70 -51 -149 0 -112 31 -160 104 -160 46 0
            69 16 91 63 34 76 19 200 -29 238 -27 21 -86 25 -115 8z m92 -38 c10 -10 21
            -39 25 -65 20 -129 -45 -223 -103 -150 -30 39 -30 169 0 208 24 31 52 33 78 7z"
            />
            <path
              d="M844 7799 c-26 -27 -29 -38 -33 -110 -2 -49 2 -91 9 -110 38 -90 149
            -92 186 -3 17 40 18 141 3 181 -19 50 -48 73 -94 73 -33 0 -47 -6 -71 -31z
            m118 -39 c35 -69 15 -200 -32 -215 -55 -18 -89 53 -78 166 9 91 75 120 110 49z"
            />
            <path
              d="M1127 7810 c-63 -50 -63 -230 0 -280 37 -29 94 -27 126 6 62 62 54
            248 -12 284 -32 16 -86 12 -114 -10z m102 -36 c17 -22 21 -41 21 -104 0 -63
            -4 -82 -21 -104 -40 -50 -91 -22 -103 58 -20 130 45 224 103 150z"
            />
            <path
              d="M1407 7819 c-47 -28 -70 -172 -38 -246 25 -61 103 -83 153 -44 63 50
            64 231 1 281 -28 22 -86 26 -116 9z m92 -38 c24 -24 35 -87 26 -152 -12 -84
            -63 -115 -104 -63 -17 22 -21 41 -21 104 0 63 4 82 21 104 24 31 52 33 78 7z"
            />
            <path
              d="M1685 7821 c-21 -4 -31 -13 -33 -28 -3 -20 -1 -21 30 -12 18 5 34 9
            35 9 2 0 3 -54 3 -120 l0 -120 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90
            -20 83 0 90 1 90 20 0 16 -7 20 -35 20 l-35 0 0 140 c0 156 5 148 -75 131z"
            />
            <path
              d="M1955 7821 c-21 -4 -31 -13 -33 -28 -3 -19 0 -21 32 -13 l36 8 0
            -119 0 -119 -29 0 c-30 0 -51 -27 -27 -36 7 -3 48 -4 92 -2 66 3 79 6 82 21 3
            14 -4 17 -32 17 l-36 0 0 140 0 140 -27 -1 c-16 -1 -41 -4 -58 -8z"
            />
            <path
              d="M2227 7819 c-64 -38 -71 -222 -10 -283 52 -52 142 -24 163 52 23 84
            6 188 -37 222 -28 22 -86 26 -116 9z m92 -38 c11 -11 23 -40 26 -65 19 -126
            -42 -220 -101 -155 -15 16 -20 40 -22 103 -3 71 -1 84 18 109 25 32 52 35 79
            8z"
            />
            <path
              d="M2505 7821 c-21 -4 -31 -13 -33 -28 -3 -20 -1 -21 30 -12 18 5 34 9
            35 9 2 0 3 -54 3 -120 l0 -120 -36 0 c-28 0 -35 -3 -32 -17 3 -15 17 -18 91
            -21 82 -3 87 -2 87 18 0 16 -6 20 -35 20 l-35 0 0 140 c0 156 5 148 -75 131z"
            />
            <path
              d="M2775 7821 c-21 -4 -31 -13 -33 -28 -3 -19 0 -21 32 -13 20 4 38 5
            40 1 2 -3 5 -57 5 -118 l2 -113 -35 0 c-30 0 -36 -4 -36 -20 0 -20 5 -21 87
            -18 74 3 88 6 91 21 3 14 -4 17 -32 17 l-36 0 0 140 0 140 -27 -1 c-16 -1 -41
            -4 -58 -8z"
            />
            <path
              d="M3043 7815 c-59 -42 -63 -222 -6 -279 32 -33 89 -35 126 -6 63 50 63
            230 0 280 -31 24 -89 27 -120 5z m96 -34 c11 -11 23 -40 26 -65 19 -126 -42
            -220 -101 -155 -16 17 -19 37 -19 109 0 72 3 92 19 109 24 26 50 27 75 2z"
            />
            <path
              d="M3312 7808 c-11 -13 -25 -36 -31 -51 -15 -40 -14 -141 3 -181 29 -70
            102 -87 158 -37 30 26 33 35 36 100 6 93 -3 133 -35 165 -34 35 -100 37 -131
            4z m103 -28 c21 -23 32 -116 21 -175 -15 -76 -92 -83 -116 -10 -16 48 -9 145
            13 179 21 32 57 34 82 6z"
            />
            <path
              d="M3585 7806 c-26 -26 -45 -83 -45 -136 0 -97 43 -160 110 -160 67 0
            110 63 110 160 0 97 -43 160 -110 160 -30 0 -47 -6 -65 -24z m91 -14 c48 -32
            52 -188 6 -234 -28 -29 -66 -18 -83 24 -8 18 -14 60 -13 93 1 79 24 125 64
            125 8 0 20 -4 26 -8z"
            />
            <path
              d="M3870 7821 c-23 -4 -36 -12 -38 -25 -4 -17 1 -18 37 -13 l41 5 0
            -119 0 -119 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20
            0 16 -7 20 -35 20 l-35 0 0 140 c0 157 7 147 -80 131z"
            />
            <path
              d="M4145 7821 c-21 -4 -31 -13 -33 -28 -3 -20 -1 -21 30 -12 18 5 34 9
            35 9 2 0 3 -54 3 -120 l0 -120 -36 0 c-28 0 -35 -3 -32 -17 3 -15 17 -18 91
            -21 82 -3 87 -2 87 18 0 16 -6 20 -35 20 l-35 0 0 140 c0 156 5 148 -75 131z"
            />
            <path
              d="M4415 7821 c-21 -4 -31 -13 -33 -29 -2 -14 1 -21 10 -18 7 2 25 7 41
            10 l27 6 0 -120 0 -120 -35 0 c-29 0 -35 -4 -35 -20 0 -20 5 -21 87 -18 74 3
            88 6 91 21 3 14 -4 17 -32 17 l-36 0 0 140 0 140 -27 -1 c-16 -1 -41 -4 -58
            -8z"
            />
            <path
              d="M4715 7825 c-5 -2 -22 -6 -37 -9 -17 -4 -28 -13 -28 -23 0 -13 8 -15
            40 -10 l40 5 0 -119 0 -119 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20
            83 0 90 1 90 20 0 16 -7 20 -35 20 l-35 0 0 140 c0 139 0 140 -22 139 -13 0
            -27 -2 -33 -4z"
            />
            <path
              d="M4965 7821 c-21 -4 -31 -13 -33 -28 -3 -20 -1 -21 30 -12 18 5 34 9
            35 9 2 0 3 -54 3 -120 l0 -120 -36 0 c-28 0 -35 -3 -32 -17 3 -15 17 -18 91
            -21 82 -3 87 -2 87 18 0 16 -6 20 -35 20 l-36 0 2 138 1 137 -23 2 c-13 0 -37
            -2 -54 -6z"
            />
            <path
              d="M5225 7806 c-63 -63 -53 -249 14 -286 61 -32 130 2 150 76 22 79 7
            169 -34 210 -33 33 -97 33 -130 0z m104 -25 c49 -49 30 -223 -27 -238 -20 -5
            -31 0 -49 21 -38 45 -31 200 11 228 19 13 45 9 65 -11z"
            />
            <path
              d="M5535 7825 c-5 -2 -22 -6 -37 -9 -17 -4 -28 -13 -28 -23 0 -13 8 -15
            40 -10 l40 5 0 -119 0 -119 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20
            83 0 90 1 90 20 0 16 -7 20 -35 20 l-35 0 0 140 c0 139 0 140 -22 139 -13 0
            -27 -2 -33 -4z"
            />
            <path
              d="M5785 7821 c-21 -4 -31 -13 -33 -28 -3 -20 -1 -21 30 -12 18 5 34 9
            35 9 2 0 3 -54 3 -120 l0 -120 -36 0 c-28 0 -35 -3 -32 -17 3 -15 17 -18 91
            -21 82 -3 87 -2 87 18 0 16 -6 20 -30 20 l-29 0 -3 138 -3 137 -25 2 c-14 0
            -38 -2 -55 -6z"
            />
            <path
              d="M6057 7819 c-34 -20 -51 -70 -51 -149 0 -112 31 -160 104 -160 46 0
            69 16 91 63 34 76 19 200 -29 238 -27 21 -86 25 -115 8z m92 -38 c10 -10 21
            -39 25 -65 20 -129 -45 -223 -103 -150 -36 46 -27 200 13 226 19 13 45 9 65
            -11z"
            />
            <path
              d="M6355 7825 c-5 -2 -22 -6 -37 -9 -18 -4 -25 -11 -23 -23 3 -19 4 -19
            48 -9 l27 6 0 -120 0 -120 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83
            0 90 1 90 20 0 16 -7 20 -35 20 l-35 0 0 140 c0 139 0 140 -22 139 -13 0 -27
            -2 -33 -4z"
            />
            <path
              d="M6605 7821 c-20 -4 -31 -13 -33 -28 -3 -19 0 -21 32 -13 l36 8 0
            -119 0 -119 -36 0 c-28 0 -35 -3 -32 -17 3 -16 15 -18 93 -18 78 0 90 2 93 18
            3 14 -4 17 -32 17 l-35 0 -3 138 -3 137 -25 2 c-14 0 -38 -2 -55 -6z"
            />
            <path
              d="M6875 7821 c-18 -4 -31 -14 -33 -25 -4 -17 1 -18 37 -13 l41 5 0
            -119 0 -119 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20
            0 16 -7 20 -35 20 l-35 0 0 140 0 140 -27 -1 c-16 -1 -41 -4 -58 -8z"
            />
            <path
              d="M7142 7808 c-30 -33 -42 -72 -42 -139 0 -101 38 -159 105 -159 68 0
            105 58 105 165 0 102 -36 155 -105 155 -30 0 -47 -6 -63 -22z m104 -29 c23
            -25 31 -131 14 -183 -23 -70 -87 -71 -110 0 -17 50 -8 159 15 184 23 26 57 25
            81 -1z"
            />
            <path
              d="M7425 7821 c-21 -4 -31 -13 -33 -28 -3 -19 0 -21 32 -13 l36 8 0
            -119 0 -119 -29 0 c-30 0 -51 -27 -27 -36 7 -3 48 -4 92 -2 66 3 79 6 82 21 3
            14 -4 17 -32 17 l-36 0 0 140 0 140 -27 -1 c-16 -1 -41 -4 -58 -8z"
            />
            <path
              d="M7700 7821 c-23 -4 -36 -12 -38 -25 -4 -17 1 -18 37 -13 l41 5 0
            -119 0 -119 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20
            0 16 -7 20 -35 20 l-35 0 0 140 c0 157 7 147 -80 131z"
            />
            <path
              d="M7962 7808 c-11 -13 -25 -36 -31 -51 -15 -40 -14 -141 3 -181 36 -86
            138 -89 181 -6 18 35 20 161 4 193 -25 48 -51 67 -94 67 -30 0 -47 -6 -63 -22z
            m103 -28 c21 -23 32 -116 21 -175 -15 -76 -92 -83 -116 -10 -16 48 -9 145 13
            179 21 32 57 34 82 6z"
            />
            <path
              d="M8238 7811 c-62 -49 -63 -216 -3 -277 17 -16 36 -24 60 -24 78 0 109
            46 109 160 0 80 -18 131 -53 150 -31 16 -86 12 -113 -9z m88 -19 c24 -15 34
            -54 34 -125 0 -60 -4 -79 -21 -101 -55 -70 -120 17 -104 142 9 73 51 111 91
            84z"
            />
            <path
              d="M8513 7815 c-39 -27 -58 -129 -40 -216 23 -113 166 -120 197 -11 23
            84 6 188 -37 222 -31 24 -89 27 -120 5z m96 -34 c11 -11 23 -40 26 -65 19
            -126 -42 -220 -101 -155 -16 17 -19 37 -19 109 0 72 3 92 19 109 24 26 50 27
            75 2z"
            />
            <path
              d="M8795 7821 c-21 -4 -31 -13 -33 -28 -3 -20 -1 -21 30 -12 18 5 34 9
            35 9 2 0 3 -54 3 -120 l0 -120 -36 0 c-28 0 -35 -3 -32 -17 3 -15 17 -18 91
            -21 82 -3 87 -2 87 18 0 16 -6 20 -35 20 l-35 0 0 140 c0 156 5 148 -75 131z"
            />
            <path
              d="M9055 7806 c-26 -26 -45 -83 -45 -136 0 -53 19 -110 45 -136 26 -26
            81 -32 116 -14 67 37 77 223 14 286 -33 33 -97 33 -130 0z m91 -14 c48 -32 52
            -188 6 -234 -28 -29 -66 -18 -83 24 -8 18 -14 60 -13 93 1 79 24 125 64 125 8
            0 20 -4 26 -8z"
            />
            <path
              d="M9340 7821 c-23 -4 -36 -12 -38 -25 -4 -17 1 -18 37 -13 l41 5 0
            -119 0 -119 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20
            0 16 -7 20 -35 20 l-35 0 0 140 c0 157 7 147 -80 131z"
            />
            <path
              d="M9611 7814 c-33 -24 -51 -74 -51 -144 0 -33 6 -76 14 -94 29 -70 102
            -87 158 -37 30 26 33 35 36 100 6 93 -3 133 -35 165 -30 31 -86 35 -122 10z
            m94 -34 c21 -23 32 -116 21 -175 -8 -41 -44 -70 -76 -60 -59 19 -68 200 -13
            243 24 18 47 15 68 -8z"
            />
            <path
              d="M9875 7806 c-26 -26 -45 -83 -45 -136 0 -97 43 -160 110 -160 67 0
            110 63 110 160 0 97 -43 160 -110 160 -30 0 -47 -6 -65 -24z m91 -14 c42 -28
            53 -172 17 -220 -26 -35 -60 -35 -86 0 -15 21 -20 42 -20 95 0 87 22 133 63
            133 8 0 20 -4 26 -8z"
            />
            <path
              d="M10147 7804 c-32 -32 -41 -72 -35 -165 3 -65 6 -74 36 -100 56 -50
            129 -33 158 37 8 18 14 61 14 94 0 105 -38 160 -111 160 -26 0 -44 -8 -62 -26z
            m115 -44 c35 -69 15 -200 -32 -215 -32 -10 -68 19 -76 60 -11 60 0 152 21 175
            28 31 66 23 87 -20z"
            />
            <path
              d="M10431 7814 c-33 -24 -51 -74 -51 -144 0 -100 38 -160 102 -160 74 0
            109 50 109 158 0 74 -10 111 -41 142 -25 25 -86 27 -119 4z m95 -35 c16 -17
            19 -37 19 -109 0 -72 -3 -92 -19 -109 -59 -65 -120 29 -101 155 11 76 61 107
            101 63z"
            />
            <path
              d="M10695 7806 c-26 -26 -45 -83 -45 -136 0 -97 43 -160 110 -160 67 0
            110 63 110 160 0 97 -43 160 -110 160 -30 0 -47 -6 -65 -24z m104 -25 c49 -49
            30 -223 -27 -238 -20 -5 -31 0 -49 21 -23 27 -28 59 -23 141 5 84 53 122 99
            76z"
            />
            <path
              d="M10971 7809 c-32 -30 -41 -61 -41 -140 0 -104 37 -159 105 -159 67 0
            105 58 105 159 0 67 -12 106 -42 139 -29 30 -95 31 -127 1z m111 -49 c35 -69
            15 -200 -32 -215 -32 -10 -68 19 -76 60 -11 60 0 152 21 175 28 31 66 23 87
            -20z"
            />
            <path
              d="M11251 7814 c-47 -33 -65 -137 -41 -226 31 -109 174 -102 197 11 18
            87 -1 189 -40 216 -28 20 -87 19 -116 -1z m99 -41 c19 -25 21 -38 18 -109 -2
            -63 -7 -87 -22 -103 -40 -44 -90 -13 -101 63 -20 131 46 224 105 149z"
            />
            <path
              d="M11525 7821 c-18 -4 -31 -14 -33 -25 -4 -17 1 -18 37 -13 l41 5 0
            -119 0 -119 -35 0 c-29 0 -35 -4 -35 -20 0 -20 5 -21 87 -18 74 3 88 6 91 21
            3 14 -4 17 -32 17 l-36 0 0 140 0 140 -27 -1 c-16 -1 -41 -4 -58 -8z"
            />
            <path
              d="M11825 7825 c-5 -2 -22 -6 -37 -9 -18 -4 -25 -11 -23 -23 3 -19 4
            -19 48 -9 l27 6 0 -120 0 -120 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90
            -20 83 0 90 1 90 20 0 16 -7 20 -35 20 l-35 0 0 140 c0 139 0 140 -22 139 -13
            0 -27 -2 -33 -4z"
            />
            <path
              d="M12075 7821 c-20 -4 -31 -13 -33 -28 -3 -19 0 -21 32 -13 l36 8 0
            -119 0 -119 -36 0 c-28 0 -35 -3 -32 -17 3 -16 15 -18 93 -18 78 0 90 2 93 18
            3 14 -4 17 -32 17 l-35 0 -3 138 -3 137 -25 2 c-14 0 -38 -2 -55 -6z"
            />
            <path
              d="M12347 7819 c-34 -20 -51 -70 -51 -149 0 -112 31 -160 104 -160 46 0
            69 16 91 63 34 77 20 199 -28 237 -28 22 -86 26 -116 9z m92 -38 c10 -10 21
            -39 25 -65 20 -130 -45 -224 -103 -150 -17 22 -21 41 -21 104 0 63 4 82 21
            104 24 31 52 33 78 7z"
            />
            <path
              d="M12613 7808 c-35 -39 -43 -64 -43 -133 0 -107 37 -165 105 -165 68 0
            105 58 105 165 0 102 -36 155 -105 155 -30 0 -47 -6 -62 -22z m103 -29 c23
            -25 31 -131 14 -183 -23 -70 -87 -71 -110 0 -17 50 -8 159 15 184 23 26 57 25
            81 -1z"
            />
            <path
              d="M64 7301 c-49 -21 -64 -57 -64 -153 0 -99 14 -138 57 -157 42 -19 73
            -13 108 22 27 27 34 44 40 90 9 69 -8 147 -39 176 -26 24 -72 34 -102 22z m82
            -57 c24 -36 24 -162 0 -198 -23 -36 -69 -36 -92 0 -23 35 -23 164 0 199 23 35
            70 34 92 -1z"
            />
            <path
              d="M890 7303 c-56 -22 -87 -104 -75 -200 15 -122 133 -167 189 -71 39
            65 30 202 -17 246 -24 23 -72 35 -97 25z m77 -60 c24 -37 24 -159 0 -196 -23
            -36 -70 -37 -92 -3 -26 39 -21 178 7 214 19 22 66 14 85 -15z"
            />
            <path
              d="M1152 7295 c-43 -19 -62 -66 -62 -152 0 -116 50 -177 128 -157 53 14
            76 52 80 135 6 94 -17 155 -64 175 -40 17 -41 17 -82 -1z m82 -44 c20 -22 32
            -112 22 -165 -9 -45 -30 -66 -67 -66 -25 0 -33 6 -45 35 -26 62 -14 186 19
            207 21 14 53 8 71 -11z"
            />
            <path
              d="M1431 7297 c-44 -22 -65 -62 -69 -133 -5 -82 17 -148 58 -169 88 -45
            153 19 153 150 0 86 -20 131 -68 151 -41 17 -44 17 -74 1z m84 -57 c18 -36 20
            -155 2 -190 -17 -34 -66 -39 -93 -9 -26 29 -28 162 -1 202 25 39 71 38 92 -3z"
            />
            <path
              d="M3071 7297 c-43 -22 -63 -60 -68 -131 -10 -113 34 -186 111 -186 43
            0 83 44 96 105 30 143 -47 260 -139 212z m86 -57 c7 -14 13 -56 13 -95 0 -88
            -15 -119 -57 -123 -48 -5 -68 32 -68 124 1 91 21 127 68 122 22 -2 35 -11 44
            -28z"
            />
            <path
              d="M3891 7297 c-49 -25 -66 -62 -66 -145 0 -90 17 -137 56 -157 93 -48
            173 47 154 183 -14 102 -77 154 -144 119z m86 -57 c19 -37 17 -170 -3 -198
            -22 -32 -69 -30 -91 5 -25 37 -24 159 0 197 24 36 75 34 94 -4z"
            />
            <path
              d="M4983 7295 c-46 -20 -65 -64 -65 -150 0 -132 64 -196 151 -150 84 43
            80 265 -5 301 -40 17 -41 17 -81 -1z m90 -65 c24 -46 19 -160 -8 -190 -28 -31
            -71 -26 -90 10 -18 35 -20 151 -4 183 27 50 75 49 102 -3z"
            />
            <path
              d="M5802 7295 c-44 -19 -62 -65 -62 -158 0 -60 4 -80 23 -108 28 -41 74
            -57 116 -39 44 18 63 55 69 132 6 92 -16 154 -64 174 -40 17 -41 17 -82 -1z
            m84 -46 c15 -17 19 -37 19 -104 0 -67 -4 -87 -19 -104 -29 -32 -72 -28 -91 9
            -27 53 -16 191 18 212 22 14 54 8 73 -13z"
            />
            <path
              d="M6074 7296 c-45 -20 -68 -84 -62 -174 6 -75 24 -112 64 -130 86 -40
            145 23 145 153 0 89 -18 131 -66 151 -41 17 -42 17 -81 0z m72 -34 c36 -24 48
            -157 19 -212 -19 -37 -62 -41 -91 -9 -26 29 -27 162 -1 202 17 26 50 35 73 19z"
            />
            <path
              d="M6360 7303 c-56 -22 -87 -104 -75 -200 15 -122 133 -167 189 -71 39
            65 30 202 -17 246 -24 23 -72 35 -97 25z m77 -60 c12 -19 17 -47 17 -98 0 -87
            -19 -125 -63 -125 -16 0 -34 7 -41 16 -30 35 -29 184 1 220 20 24 66 17 86
            -13z"
            />
            <path
              d="M6901 7297 c-44 -22 -65 -62 -69 -133 -5 -82 17 -148 58 -169 88 -45
            153 19 153 150 0 86 -20 131 -68 151 -41 17 -44 17 -74 1z m84 -57 c18 -36 20
            -155 2 -190 -17 -34 -66 -39 -93 -9 -26 29 -28 162 -1 202 25 39 71 38 92 -3z"
            />
            <path
              d="M7442 7295 c-43 -19 -62 -66 -62 -152 0 -116 50 -177 128 -157 55 14
            76 52 80 144 5 96 -14 145 -64 166 -40 17 -41 17 -82 -1z m84 -46 c26 -28 32
            -141 11 -192 -14 -33 -20 -37 -52 -37 -32 0 -38 4 -52 37 -21 51 -15 164 11
            192 11 12 29 21 41 21 12 0 30 -9 41 -21z"
            />
            <path
              d="M7721 7297 c-65 -33 -92 -150 -56 -243 28 -73 98 -96 153 -49 75 63
            59 264 -24 295 -33 13 -44 12 -73 -3z m84 -57 c18 -36 20 -155 2 -190 -19 -38
            -70 -40 -94 -4 -25 39 -25 159 0 197 25 39 71 38 92 -3z"
            />
            <path
              d="M7993 7300 c-81 -33 -95 -233 -22 -295 50 -41 122 -26 148 32 19 40
            25 131 12 179 -18 69 -82 108 -138 84z m84 -57 c25 -38 25 -158 0 -197 -24
            -36 -75 -34 -94 4 -18 35 -16 154 2 190 21 41 67 42 92 3z"
            />
            <path
              d="M8262 7295 c-43 -19 -62 -66 -62 -152 0 -116 50 -177 128 -157 55 14
            76 52 80 144 5 96 -14 145 -64 166 -40 17 -41 17 -82 -1z m84 -46 c26 -28 32
            -141 11 -192 -14 -33 -20 -37 -52 -37 -32 0 -38 4 -52 37 -21 51 -15 164 11
            192 11 12 29 21 41 21 12 0 30 -9 41 -21z"
            />
            <path
              d="M8541 7297 c-43 -22 -63 -60 -68 -131 -10 -113 34 -186 111 -186 43
            0 83 44 96 105 30 143 -47 260 -139 212z m86 -57 c7 -14 13 -56 13 -95 0 -88
            -15 -119 -57 -123 -48 -5 -68 32 -68 123 0 91 20 128 68 123 22 -2 35 -11 44
            -28z"
            />
            <path
              d="M8813 7300 c-81 -33 -95 -233 -22 -295 35 -28 67 -32 108 -10 72 37
            83 222 18 283 -27 26 -73 35 -104 22z m84 -57 c25 -38 25 -158 0 -197 -24 -36
            -75 -34 -94 4 -18 35 -16 154 2 190 21 41 67 42 92 3z"
            />
            <path
              d="M9084 7296 c-43 -19 -64 -69 -64 -153 0 -116 50 -177 128 -157 55 14
            76 52 80 143 5 99 -13 146 -62 166 -42 18 -43 18 -82 1z m82 -47 c26 -28 32
            -141 11 -192 -14 -33 -20 -37 -52 -37 -32 0 -38 4 -52 37 -21 51 -15 164 11
            192 11 12 29 21 41 21 12 0 30 -9 41 -21z"
            />
            <path
              d="M10181 7298 c-49 -26 -66 -63 -66 -146 0 -90 17 -137 56 -157 45 -24
            77 -19 114 18 40 40 54 117 35 196 -18 78 -82 119 -139 89z m87 -61 c20 -40
            18 -162 -2 -192 -23 -35 -70 -34 -93 2 -25 37 -24 159 0 197 25 38 76 34 95
            -7z"
            />
            <path
              d="M10453 7295 c-46 -20 -65 -64 -65 -150 0 -132 64 -196 151 -150 84
            43 80 265 -5 301 -40 17 -41 17 -81 -1z m90 -65 c24 -46 19 -160 -9 -191 -25
            -28 -74 -22 -91 11 -16 31 -17 154 -2 183 27 50 75 49 102 -3z"
            />
            <path
              d="M10724 7296 c-40 -18 -64 -76 -64 -159 0 -110 51 -170 129 -151 56
            14 81 60 81 151 0 95 -18 139 -64 158 -42 18 -43 18 -82 1z m73 -34 c34 -21
            45 -159 18 -212 -19 -37 -62 -41 -91 -9 -15 17 -19 37 -19 104 0 67 4 87 19
            104 19 21 51 27 73 13z"
            />
            <path
              d="M11001 7298 c-50 -27 -66 -62 -66 -151 0 -63 4 -89 19 -114 38 -62
            101 -70 151 -20 40 40 54 117 35 196 -18 78 -82 119 -139 89z m85 -54 c12 -18
            17 -47 17 -99 0 -89 -18 -125 -63 -125 -45 0 -63 36 -63 125 0 89 18 125 63
            125 21 0 35 -8 46 -26z"
            />
            <path
              d="M11830 7303 c-56 -22 -87 -104 -75 -200 15 -122 133 -167 189 -71 39
            65 30 202 -17 246 -24 23 -72 35 -97 25z m77 -60 c12 -19 17 -47 17 -98 0 -87
            -19 -125 -63 -125 -16 0 -34 7 -41 16 -30 35 -29 184 1 220 20 24 66 17 86
            -13z"
            />
            <path
              d="M12092 7295 c-44 -19 -62 -65 -62 -158 0 -91 25 -137 81 -151 78 -19
            129 41 129 151 0 85 -24 141 -66 159 -40 17 -41 17 -82 -1z m81 -43 c34 -37
            34 -184 1 -217 -9 -8 -29 -15 -45 -15 -25 0 -33 6 -45 35 -26 62 -14 186 19
            207 21 13 53 9 70 -10z"
            />
            <path
              d="M12363 7294 c-83 -41 -85 -257 -2 -299 87 -46 153 19 152 151 -1 86
            -21 130 -68 150 -42 17 -43 17 -82 -2z m92 -54 c18 -36 20 -155 2 -190 -17
            -34 -66 -39 -93 -9 -26 29 -28 162 -1 202 25 39 71 38 92 -3z"
            />
            <path
              d="M310 7291 c-14 -4 -26 -15 -28 -25 -3 -16 1 -17 37 -11 l41 7 0 -121
            0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15 0 10
            -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -44 -4 -58 -8z"
            />
            <path
              d="M583 7293 c-15 -3 -23 -12 -23 -25 0 -18 4 -19 35 -14 l35 7 0 -121
            0 -120 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 72 0 90 3 90 14 0 9
            -12 16 -32 18 l-33 3 0 137 0 138 -35 -1 c-19 -1 -45 -4 -57 -6z"
            />
            <path
              d="M1678 7293 c-20 -3 -28 -10 -28 -25 0 -18 4 -19 40 -13 l40 7 0 -121
            0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15 0 10
            -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M1951 7291 c-13 -3 -25 -15 -27 -25 -2 -16 2 -18 37 -12 l39 6 0
            -120 0 -120 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15
            0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -44 -4 -57 -8z"
            />
            <path
              d="M2228 7293 c-20 -3 -28 -10 -28 -25 0 -18 4 -19 35 -14 l35 7 0 -121
            0 -120 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 93 -15 77 0 94 3 94 15 0 10
            -11 15 -34 15 l-33 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M2498 7293 c-20 -3 -28 -10 -28 -25 0 -18 4 -19 40 -13 l40 7 0 -121
            0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15 0 10
            -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M2773 7293 c-15 -3 -23 -12 -23 -25 0 -18 4 -19 35 -14 l35 7 0 -121
            0 -120 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15 0 10
            -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -43 -4 -55 -6z"
            />
            <path
              d="M3318 7293 c-20 -3 -28 -10 -28 -25 0 -18 4 -19 40 -13 l40 7 0 -121
            0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15 0 10
            -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M3593 7293 c-15 -3 -23 -12 -23 -25 0 -18 4 -19 35 -14 l35 7 0 -121
            0 -120 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15 0 10
            -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -43 -4 -55 -6z"
            />
            <path
              d="M4140 7291 c-14 -4 -26 -15 -28 -25 -3 -16 1 -17 37 -11 l41 7 0
            -121 0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15
            0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -44 -4 -58 -8z"
            />
            <path
              d="M4413 7293 c-15 -3 -23 -12 -23 -25 0 -18 4 -19 35 -14 l35 7 0 -121
            0 -120 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 72 0 90 3 90 14 0 9
            -12 16 -32 18 l-33 3 -3 138 -3 137 -32 -1 c-18 -1 -42 -4 -54 -6z"
            />
            <path
              d="M4688 7293 c-20 -3 -28 -10 -28 -25 0 -18 4 -19 40 -13 l41 7 -3
            -119 -3 -118 -32 -3 c-21 -2 -33 -9 -33 -18 0 -11 18 -14 90 -14 73 0 90 3 90
            15 0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M5233 7293 c-15 -3 -23 -12 -23 -25 0 -18 4 -19 35 -14 l35 7 0 -121
            0 -120 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 72 0 90 3 90 14 0 9
            -12 16 -32 18 l-33 3 -3 138 -3 137 -32 -1 c-18 -1 -42 -4 -54 -6z"
            />
            <path
              d="M5508 7293 c-20 -3 -28 -10 -28 -25 0 -18 4 -19 40 -13 l41 7 -3
            -119 -3 -118 -32 -3 c-21 -2 -33 -9 -33 -18 0 -11 18 -14 90 -14 73 0 90 3 90
            15 0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M6600 7291 c-14 -4 -26 -15 -28 -25 -4 -17 1 -18 37 -12 l41 6 0
            -120 0 -120 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15
            0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -44 -4 -58 -8z"
            />
            <path
              d="M7148 7293 c-20 -3 -28 -10 -28 -25 0 -18 4 -19 40 -13 l40 7 0 -121
            0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15 0 10
            -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M9338 7293 c-20 -3 -28 -10 -28 -25 0 -17 4 -19 38 -13 l37 6 -2
            -118 -2 -118 -30 -3 c-19 -2 -31 -9 -31 -18 0 -11 18 -14 90 -14 73 0 90 3 90
            15 0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M9610 7291 c-14 -4 -26 -15 -28 -25 -3 -16 1 -17 37 -11 l41 7 0
            -121 0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15
            0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -44 -4 -58 -8z"
            />
            <path
              d="M9883 7293 c-15 -3 -23 -12 -23 -25 0 -18 4 -19 35 -14 l35 7 0 -121
            0 -120 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 72 0 90 3 90 14 0 9
            -12 16 -32 18 l-33 3 -3 138 -3 137 -32 -1 c-18 -1 -42 -4 -54 -6z"
            />
            <path
              d="M11250 7291 c-14 -4 -26 -15 -28 -25 -3 -16 1 -17 37 -11 l41 7 0
            -121 0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15
            0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -44 -4 -58 -8z"
            />
            <path
              d="M11523 7293 c-15 -3 -23 -12 -23 -25 0 -18 4 -19 35 -14 l35 7 0
            -121 0 -120 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 72 0 90 3 90 14
            0 9 -12 16 -32 18 l-33 3 0 138 0 137 -35 -1 c-19 -1 -45 -4 -57 -6z"
            />
            <path
              d="M12618 7293 c-20 -3 -28 -10 -28 -25 0 -18 4 -19 40 -13 l40 7 0
            -121 0 -121 -35 0 c-24 0 -35 -5 -35 -15 0 -12 17 -15 90 -15 73 0 90 3 90 15
            0 10 -11 15 -35 15 l-35 0 0 140 0 140 -32 -1 c-18 -1 -45 -4 -60 -6z"
            />
            <path
              d="M51 6766 c-40 -22 -50 -54 -51 -153 0 -83 2 -94 25 -121 30 -36 69
            -45 112 -27 56 24 83 113 63 209 -18 88 -83 128 -149 92z m84 -41 c22 -21 25
            -33 25 -100 0 -99 -18 -135 -67 -135 -67 0 -84 200 -20 245 29 20 34 19 62
            -10z"
            />
            <path
              d="M311 6755 c-52 -44 -64 -170 -24 -248 17 -33 69 -58 104 -50 57 14
            89 72 89 165 0 128 -90 199 -169 133z m94 -27 c47 -42 43 -201 -6 -231 -26
            -16 -65 0 -79 33 -7 18 -11 59 -8 106 3 65 7 79 27 95 30 24 34 24 66 -3z"
            />
            <path
              d="M591 6764 c-34 -24 -51 -74 -51 -151 0 -88 22 -133 74 -150 83 -28
            136 31 136 151 0 108 -37 166 -105 166 -17 0 -41 -7 -54 -16z m88 -33 c25 -25
            35 -76 29 -146 -5 -61 -28 -95 -63 -95 -37 0 -57 33 -62 103 -6 76 10 135 41
            147 31 13 34 12 55 -9z"
            />
            <path
              d="M1411 6764 c-33 -24 -51 -74 -51 -146 1 -89 27 -143 76 -157 52 -14
            100 7 119 53 19 45 19 156 1 200 -17 41 -51 66 -91 66 -17 0 -41 -7 -54 -16z
            m89 -34 c26 -26 33 -58 28 -135 -4 -70 -25 -105 -63 -105 -37 0 -57 33 -62
            103 -6 76 10 135 41 147 31 13 34 12 56 -10z"
            />
            <path
              d="M2240 6767 c-59 -29 -80 -166 -41 -254 24 -52 73 -71 128 -48 44 19
            63 64 63 155 0 128 -64 190 -150 147z m80 -37 c26 -26 33 -58 28 -135 -4 -71
            -25 -105 -64 -105 -32 0 -57 41 -62 103 -6 70 13 136 41 147 32 13 35 12 57
            -10z"
            />
            <path
              d="M2509 6765 c-48 -26 -71 -124 -50 -213 16 -65 47 -95 101 -95 54 0
            85 30 101 95 36 151 -49 269 -152 213z m81 -33 c40 -33 48 -164 14 -219 -10
            -16 -24 -23 -44 -23 -51 0 -79 79 -61 176 8 44 37 84 61 84 4 0 18 -8 30 -18z"
            />
            <path
              d="M2782 6765 c-37 -27 -52 -68 -52 -145 0 -91 19 -136 64 -155 34 -15
            50 -13 93 7 62 30 72 229 14 282 -28 25 -91 31 -119 11z m72 -25 c59 -22 61
            -224 1 -246 -30 -12 -63 5 -74 37 -25 72 -6 197 31 212 7 3 14 6 15 6 1 1 13
            -3 27 -9z"
            />
            <path
              d="M4421 6764 c-13 -9 -29 -32 -37 -50 -18 -44 -18 -155 1 -200 19 -46
            67 -67 119 -53 50 14 76 66 76 154 0 107 -37 165 -105 165 -17 0 -41 -7 -54
            -16z m106 -63 c20 -48 13 -163 -12 -191 -23 -26 -57 -25 -81 1 -23 25 -31 132
            -14 184 21 64 82 67 107 6z"
            />
            <path
              d="M5241 6764 c-34 -24 -51 -74 -51 -150 0 -106 33 -156 105 -156 72 0
            105 50 105 156 0 108 -37 166 -105 166 -17 0 -41 -7 -54 -16z m88 -33 c25 -25
            35 -76 29 -146 -5 -61 -28 -95 -63 -95 -38 0 -59 35 -63 105 -5 75 12 133 42
            145 31 13 34 12 55 -9z"
            />
            <path
              d="M6339 6765 c-30 -16 -47 -51 -56 -110 -18 -136 52 -228 144 -190 37
            16 58 52 68 117 20 134 -64 233 -156 183z m86 -40 c22 -21 25 -33 25 -100 0
            -96 -18 -135 -60 -135 -20 0 -34 7 -44 23 -35 56 -30 174 10 214 29 29 39 29
            69 -2z"
            />
            <path
              d="M7159 6765 c-32 -18 -50 -56 -56 -125 -10 -112 31 -183 107 -183 53
            0 85 30 100 95 14 58 6 139 -17 179 -28 47 -84 61 -134 34z m81 -33 c39 -31
            45 -185 9 -228 -18 -22 -66 -17 -83 9 -35 56 -30 174 10 214 28 28 35 28 64 5z"
            />
            <path
              d="M7432 6765 c-38 -28 -54 -72 -54 -150 0 -87 19 -131 66 -150 100 -42
            170 71 140 222 -15 74 -98 117 -152 78z m72 -25 c43 -16 61 -134 31 -205 -16
            -39 -34 -50 -69 -41 -35 9 -49 53 -44 142 3 65 7 79 27 95 27 22 23 22 55 9z"
            />
            <path
              d="M7710 6767 c-59 -29 -80 -166 -41 -254 24 -52 73 -71 128 -48 44 19
            63 64 63 155 0 128 -64 190 -150 147z m80 -37 c26 -26 33 -58 28 -135 -4 -71
            -25 -105 -64 -105 -32 0 -57 41 -62 103 -6 70 13 136 41 147 32 13 35 12 57
            -10z"
            />
            <path
              d="M8252 6765 c-37 -27 -52 -68 -52 -145 0 -91 19 -136 64 -155 34 -15
            50 -13 93 7 62 30 72 229 14 282 -28 25 -91 31 -119 11z m72 -25 c59 -22 61
            -224 1 -246 -30 -12 -63 5 -74 37 -25 72 -6 197 31 212 7 3 14 6 15 6 1 1 13
            -3 27 -9z"
            />
            <path
              d="M8804 6769 c-49 -24 -76 -129 -54 -217 15 -65 47 -95 100 -95 29 0
            48 7 65 23 45 42 57 170 24 244 -22 47 -86 68 -135 45z m75 -36 c42 -34 50
            -164 15 -220 -17 -26 -65 -31 -83 -9 -36 43 -30 197 9 228 28 22 32 22 59 1z"
            />
            <path
              d="M9072 6764 c-37 -26 -52 -67 -52 -144 0 -91 19 -136 63 -155 55 -23
            104 -4 128 48 22 51 25 153 5 201 -26 61 -94 85 -144 50z m105 -63 c20 -48 13
            -163 -12 -191 -23 -26 -57 -25 -81 1 -24 26 -32 145 -14 190 24 58 83 58 107
            0z"
            />
            <path
              d="M9352 6770 c-33 -14 -62 -84 -62 -153 0 -120 63 -187 146 -152 38 16
            59 52 67 115 18 137 -55 228 -151 190z m79 -39 c20 -16 24 -30 27 -95 3 -47
            -1 -88 -8 -106 -14 -33 -53 -49 -79 -33 -27 17 -43 82 -38 148 5 49 10 64 32
            82 32 28 36 28 66 4z"
            />
            <path
              d="M9624 6769 c-71 -35 -84 -211 -21 -285 18 -21 32 -27 66 -27 52 0 87
            30 102 87 13 48 7 139 -12 179 -22 48 -85 70 -135 46z m80 -42 c40 -40 45
            -158 9 -215 -11 -18 -22 -22 -46 -20 -43 4 -57 35 -57 131 0 69 3 81 25 102
            30 31 40 31 69 2z"
            />
            <path
              d="M9891 6764 c-13 -9 -29 -32 -37 -50 -18 -44 -18 -155 1 -200 19 -46
            67 -67 119 -53 50 14 76 66 76 154 0 107 -37 165 -105 165 -17 0 -41 -7 -54
            -16z m106 -63 c20 -48 13 -163 -12 -191 -23 -26 -57 -25 -81 1 -23 25 -31 132
            -14 184 21 64 82 67 107 6z"
            />
            <path
              d="M10172 6770 c-33 -14 -62 -84 -62 -153 0 -120 63 -187 146 -152 57
            23 84 116 64 213 -18 82 -78 120 -148 92z m79 -39 c19 -16 24 -30 27 -90 6
            -108 -12 -151 -63 -151 -67 0 -89 177 -30 236 29 29 36 29 66 5z"
            />
            <path
              d="M10432 6755 c-76 -64 -62 -255 21 -290 92 -38 162 54 144 190 -9 60
            -27 94 -58 111 -41 21 -74 17 -107 -11z m85 -20 c30 -21 47 -87 38 -150 -8
            -61 -28 -95 -58 -95 -49 0 -67 36 -67 135 0 67 3 79 25 100 28 29 33 30 62 10z"
            />
            <path
              d="M11531 6764 c-34 -24 -51 -74 -51 -151 0 -88 22 -133 74 -150 83 -28
            136 31 136 151 0 108 -37 166 -105 166 -17 0 -41 -7 -54 -16z m88 -33 c25 -25
            35 -76 29 -146 -5 -61 -28 -95 -63 -95 -37 0 -57 33 -62 103 -6 76 10 135 41
            147 31 13 34 12 55 -9z"
            />
            <path
              d="M11809 6765 c-30 -16 -47 -51 -56 -110 -18 -136 52 -228 144 -190 37
            16 58 52 68 117 20 134 -64 233 -156 183z m86 -40 c22 -21 25 -33 25 -100 0
            -99 -18 -135 -67 -135 -64 0 -84 180 -27 237 29 29 39 29 69 -2z"
            />
            <path
              d="M12070 6753 c-50 -46 -62 -170 -23 -246 26 -53 101 -67 147 -28 70
            59 56 262 -19 291 -43 16 -73 11 -105 -17z m95 -26 c22 -18 27 -33 32 -82 5
            -66 -11 -131 -38 -148 -26 -16 -65 0 -79 33 -7 18 -11 59 -8 106 3 65 7 79 27
            95 30 24 34 24 66 -4z"
            />
            <path
              d="M12351 6764 c-33 -24 -51 -74 -51 -146 1 -89 27 -143 76 -157 52 -14
            100 7 119 53 19 45 19 156 1 200 -17 41 -51 66 -91 66 -17 0 -41 -7 -54 -16z
            m89 -34 c26 -26 33 -58 28 -135 -4 -70 -25 -105 -63 -105 -37 0 -57 33 -62
            103 -6 76 10 135 41 147 31 13 34 12 56 -10z"
            />
            <path
              d="M848 6763 c-10 -2 -18 -13 -18 -23 0 -15 6 -17 38 -13 l37 5 0 -116
            0 -116 -32 0 c-26 0 -33 -4 -33 -20 0 -19 7 -20 91 -20 81 0 90 2 87 18 -2 11
            -14 18 -36 20 l-32 3 0 135 0 134 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M1118 6763 c-10 -2 -18 -13 -18 -24 0 -17 5 -18 40 -13 l40 7 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M1668 6763 c-10 -2 -18 -13 -18 -23 0 -15 6 -17 40 -13 l40 6 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 91 -20 81 0 90 2 87 18 -2 11
            -14 18 -36 20 l-32 3 0 135 0 134 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M1938 6763 c-10 -2 -18 -13 -18 -24 0 -17 5 -18 40 -13 l40 7 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M3038 6763 c-10 -2 -18 -13 -18 -23 0 -16 5 -18 35 -13 l35 6 0 -116
            0 -116 -32 -3 c-22 -2 -34 -9 -36 -20 -3 -16 6 -18 87 -18 84 0 91 1 91 20 0
            16 -7 20 -36 20 l-35 0 3 135 3 135 -40 -1 c-22 -1 -48 -3 -57 -6z"
            />
            <path
              d="M3308 6763 c-10 -2 -18 -13 -18 -24 0 -17 5 -18 40 -13 l40 7 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M3583 6763 c-15 -3 -23 -12 -23 -24 0 -17 5 -18 40 -13 l40 7 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -37 -1 c-21 -1 -48 -4 -60 -6z"
            />
            <path
              d="M3858 6763 c-10 -2 -18 -13 -18 -23 0 -16 5 -18 35 -13 l35 6 0 -116
            0 -116 -32 -3 c-22 -2 -34 -9 -36 -20 -3 -16 6 -18 87 -18 84 0 91 1 91 20 0
            16 -7 20 -30 20 l-30 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M4128 6763 c-10 -2 -18 -13 -18 -24 0 -17 5 -18 40 -13 l40 7 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M4678 6763 c-10 -2 -18 -13 -18 -23 0 -16 5 -18 35 -13 l35 6 0 -116
            0 -116 -32 -3 c-22 -2 -34 -9 -36 -21 -3 -16 5 -18 90 -15 75 2 93 6 93 18 0
            10 -12 16 -32 18 l-33 3 0 135 0 134 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M4948 6763 c-10 -2 -18 -13 -18 -24 0 -17 5 -18 40 -13 l40 7 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M5498 6763 c-10 -2 -18 -13 -18 -23 0 -16 5 -18 35 -13 l35 6 0 -117
            0 -116 -28 0 c-34 0 -43 -7 -35 -26 4 -11 27 -14 95 -14 80 0 89 2 86 18 -2
            11 -14 18 -36 20 l-32 3 0 135 0 134 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M5768 6763 c-10 -2 -18 -13 -18 -24 0 -17 5 -18 40 -13 l40 7 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M6048 6763 c-10 -2 -18 -13 -18 -23 0 -16 5 -18 35 -13 l35 6 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -37 -1 c-21 -1 -46 -3 -55 -6z"
            />
            <path
              d="M6588 6763 c-10 -2 -18 -13 -18 -24 0 -17 5 -18 40 -13 l40 7 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M6868 6763 c-10 -2 -18 -13 -18 -23 0 -16 5 -18 35 -13 l35 6 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20 0 16
            -7 20 -35 20 l-35 0 0 135 0 135 -37 -1 c-21 -1 -46 -3 -55 -6z"
            />
            <path
              d="M7958 6763 c-10 -2 -18 -13 -18 -23 0 -15 6 -17 40 -13 l40 6 0 -117
            0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 91 -20 81 0 90 2 87 18 -2 11
            -14 18 -36 20 l-32 3 0 135 0 134 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M8508 6763 c-10 -2 -18 -13 -18 -23 0 -16 5 -18 35 -13 l35 6 0 -116
            0 -116 -32 -3 c-22 -2 -34 -9 -36 -20 -3 -16 6 -18 87 -18 84 0 91 1 91 20 0
            16 -7 20 -36 20 l-35 0 3 135 3 135 -40 -1 c-22 -1 -48 -3 -57 -6z"
            />
            <path
              d="M10698 6763 c-10 -2 -18 -13 -18 -23 0 -16 5 -18 35 -13 l35 6 0
            -117 0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20
            0 16 -7 20 -35 20 l-35 0 0 135 0 135 -37 -1 c-21 -1 -46 -3 -55 -6z"
            />
            <path
              d="M10968 6763 c-10 -2 -18 -13 -18 -23 0 -16 5 -18 35 -13 l35 6 0
            -117 0 -116 -28 0 c-34 0 -43 -7 -35 -26 4 -11 27 -14 95 -14 80 0 89 2 86 18
            -2 11 -14 18 -36 20 l-32 3 0 135 0 134 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M11238 6763 c-10 -2 -18 -13 -18 -24 0 -17 5 -18 40 -13 l40 7 0
            -117 0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20
            0 16 -7 20 -35 20 l-35 0 0 135 0 135 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M12608 6763 c-10 -2 -18 -13 -18 -23 0 -15 6 -17 40 -13 l40 6 0
            -117 0 -116 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 91 -20 81 0 90 2 87 18
            -2 11 -14 18 -36 20 l-32 3 0 135 0 134 -42 -1 c-24 -1 -51 -3 -60 -6z"
            />
            <path
              d="M47 6224 c-57 -56 -57 -212 0 -268 49 -49 130 -29 159 40 18 43 18
            145 0 188 -29 69 -110 89 -159 40z m102 -23 c11 -11 23 -40 26 -65 19 -126
            -42 -220 -101 -155 -28 31 -28 187 0 218 24 26 50 27 75 2z"
            />
            <path
              d="M335 6241 c-21 -4 -31 -13 -33 -28 -3 -19 0 -21 32 -13 l36 8 0 -119
            0 -119 -36 0 c-28 0 -35 -3 -32 -17 3 -15 17 -18 91 -21 82 -3 87 -2 87 18 0
            16 -6 20 -35 20 l-35 0 0 140 c0 156 5 148 -75 131z"
            />
            <path
              d="M635 6245 c-5 -2 -22 -6 -37 -9 -19 -4 -28 -13 -28 -25 0 -16 5 -18
            34 -11 19 4 37 5 40 2 4 -3 6 -56 6 -119 l0 -113 -35 0 c-29 0 -35 -4 -35 -20
            0 -20 5 -21 87 -18 74 3 88 6 91 21 3 14 -4 17 -32 17 l-36 0 0 140 c0 139 0
            140 -22 139 -13 0 -27 -2 -33 -4z"
            />
            <path
              d="M871 6229 c-32 -30 -41 -61 -41 -139 0 -109 31 -160 100 -160 72 0
            110 55 110 160 0 70 -18 120 -51 144 -33 23 -90 21 -118 -5z m89 -19 c39 -21
            54 -138 26 -205 -19 -45 -59 -57 -89 -26 -19 19 -22 33 -22 110 0 73 3 93 19
            110 21 23 38 26 66 11z"
            />
            <path
              d="M1151 6234 c-33 -24 -51 -74 -51 -144 0 -105 38 -160 110 -160 69 0
            100 51 100 160 0 78 -9 109 -41 139 -28 26 -85 28 -118 5z m95 -35 c16 -17 19
            -37 19 -110 0 -77 -3 -91 -22 -110 -49 -50 -103 8 -103 110 0 108 56 165 106
            110z"
            />
            <path
              d="M1415 6226 c-59 -59 -59 -213 0 -272 18 -18 35 -24 65 -24 67 0 110
            63 110 160 0 97 -43 160 -110 160 -30 0 -47 -6 -65 -24z m91 -14 c23 -15 38
            -59 38 -117 1 -97 -31 -147 -83 -130 -28 8 -43 56 -43 129 1 83 22 126 62 126
            8 0 20 -4 26 -8z"
            />
            <path
              d="M1691 6229 c-32 -30 -41 -61 -41 -139 0 -109 31 -160 100 -160 72 0
            110 55 110 160 0 70 -18 120 -51 144 -33 23 -90 21 -118 -5z m89 -19 c39 -21
            54 -138 26 -205 -19 -45 -59 -57 -89 -26 -19 19 -22 33 -22 110 0 73 3 93 19
            110 21 23 38 26 66 11z"
            />
            <path
              d="M1975 6241 c-20 -4 -31 -13 -33 -28 -3 -19 0 -21 32 -13 l36 8 0
            -119 0 -119 -36 0 c-28 0 -35 -3 -32 -17 3 -15 17 -18 91 -21 82 -3 87 -2 87
            18 0 16 -6 20 -32 20 l-33 0 0 137 0 138 -25 2 c-14 0 -38 -2 -55 -6z"
            />
            <path
              d="M2275 6245 c-5 -2 -22 -6 -37 -9 -19 -4 -28 -13 -28 -26 0 -11 5 -18
            13 -16 6 3 24 7 40 10 l27 6 0 -120 0 -120 -35 0 c-28 0 -35 -4 -35 -20 0 -19
            7 -20 90 -20 83 0 90 1 90 20 0 16 -7 20 -35 20 l-35 0 0 140 c0 139 0 140
            -22 139 -13 0 -27 -2 -33 -4z"
            />
            <path
              d="M2504 6219 c-28 -29 -29 -33 -29 -129 0 -96 1 -100 29 -129 22 -23
            39 -31 66 -31 72 0 110 55 110 160 0 105 -38 160 -110 160 -27 0 -44 -8 -66
            -31z m118 -39 c22 -43 23 -128 4 -175 -19 -45 -59 -57 -89 -26 -19 19 -22 33
            -22 110 0 74 3 92 19 110 29 32 67 24 88 -19z"
            />
            <path
              d="M2795 6241 c-20 -4 -31 -13 -33 -28 -3 -19 0 -21 32 -13 l36 8 0
            -119 0 -119 -36 0 c-28 0 -35 -3 -32 -17 3 -15 16 -18 82 -21 44 -2 85 -1 92
            2 24 9 3 36 -27 36 l-28 0 -3 138 -3 137 -25 2 c-14 0 -38 -2 -55 -6z"
            />
            <path
              d="M3095 6245 c-5 -2 -22 -6 -37 -9 -19 -4 -28 -13 -28 -26 0 -11 5 -18
            13 -16 6 3 24 7 40 10 l27 6 0 -120 0 -120 -35 0 c-28 0 -35 -4 -35 -20 0 -19
            7 -20 90 -20 83 0 90 1 90 20 0 16 -7 20 -35 20 l-35 0 0 140 c0 139 0 140
            -22 139 -13 0 -27 -2 -33 -4z"
            />
            <path
              d="M3333 6228 c-35 -39 -43 -64 -43 -138 0 -105 36 -160 105 -160 67 0
            105 58 105 160 0 105 -38 160 -110 160 -25 0 -43 -7 -57 -22z m109 -48 c35
            -69 15 -200 -32 -215 -56 -18 -90 56 -77 167 11 92 74 119 109 48z"
            />
            <path
              d="M3607 6230 c-30 -24 -47 -75 -47 -140 0 -101 39 -160 107 -160 76 0
            106 46 106 160 0 80 -17 131 -52 150 -32 16 -86 12 -114 -10z m102 -36 c17
            -22 21 -41 21 -104 0 -63 -4 -82 -21 -104 -58 -74 -123 20 -103 150 12 80 63
            108 103 58z"
            />
            <path
              d="M3887 6239 c-34 -20 -51 -70 -51 -149 0 -113 31 -160 107 -160 68 0
            107 59 107 160 0 103 -39 160 -109 160 -20 0 -44 -5 -54 -11z m92 -38 c10 -10
            21 -39 25 -65 20 -130 -45 -224 -103 -150 -17 22 -21 41 -21 104 0 63 4 82 21
            104 24 31 52 33 78 7z"
            />
            <path
              d="M4165 6241 c-21 -4 -31 -13 -33 -28 -3 -20 -1 -21 30 -12 18 5 34 9
            35 9 2 0 3 -54 3 -120 l0 -120 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90
            -20 83 0 90 1 90 20 0 16 -7 20 -35 20 l-35 0 0 140 c0 156 5 148 -75 131z"
            />
            <path
              d="M4435 6241 c-20 -4 -31 -13 -33 -28 -3 -19 0 -21 32 -13 l36 8 0
            -119 0 -119 -30 0 c-24 0 -30 -4 -30 -20 0 -20 5 -21 87 -18 74 3 88 6 91 21
            3 14 -4 17 -32 17 l-35 0 -3 138 -3 137 -25 2 c-14 0 -38 -2 -55 -6z"
            />
            <path
              d="M4735 6245 c-5 -2 -22 -6 -37 -9 -17 -4 -28 -13 -28 -23 0 -13 8 -15
            40 -10 l40 5 0 -119 0 -119 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20
            83 0 90 1 90 20 0 16 -7 20 -35 20 l-35 0 0 140 c0 139 0 140 -22 139 -13 0
            -27 -2 -33 -4z"
            />
            <path
              d="M4985 6241 c-21 -4 -31 -13 -33 -28 -3 -19 0 -21 32 -13 l36 8 0
            -119 0 -119 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20 83 0 90 1 90 20
            0 16 -7 20 -35 20 l-35 0 0 140 c0 156 5 148 -75 131z"
            />
            <path
              d="M5253 6239 c-22 -6 -33 -16 -33 -28 0 -16 5 -18 35 -11 19 4 37 5 39
            1 2 -3 5 -57 5 -118 l2 -113 -35 0 c-30 0 -36 -4 -36 -20 0 -20 5 -21 87 -18
            74 3 88 6 91 21 3 14 -4 17 -32 17 l-36 0 0 140 0 140 -27 -1 c-16 0 -43 -5
            -60 -10z"
            />
            <path
              d="M5517 6224 c-27 -27 -41 -72 -41 -134 0 -101 38 -160 104 -160 71 0
            110 57 110 160 0 103 -39 160 -110 160 -27 0 -45 -7 -63 -26z m102 -23 c11
            -11 23 -40 26 -65 19 -126 -42 -220 -101 -155 -16 17 -19 37 -19 109 0 72 3
            92 19 109 24 26 50 27 75 2z"
            />
            <path
              d="M5805 6241 c-21 -4 -31 -13 -33 -28 -3 -19 0 -21 32 -13 l36 8 0
            -119 0 -119 -36 0 c-28 0 -35 -3 -32 -17 3 -15 17 -18 91 -21 82 -3 87 -2 87
            18 0 16 -6 20 -35 20 l-35 0 0 140 c0 156 5 148 -75 131z"
            />
            <path
              d="M6105 6245 c-5 -2 -22 -6 -37 -9 -19 -4 -28 -13 -28 -25 0 -16 5 -18
            34 -11 19 4 37 5 40 2 4 -3 6 -56 6 -119 l0 -113 -35 0 c-29 0 -35 -4 -35 -20
            0 -20 5 -21 87 -18 74 3 88 6 91 21 3 14 -4 17 -32 17 l-36 0 0 140 c0 139 0
            140 -22 139 -13 0 -27 -2 -33 -4z"
            />
            <path
              d="M6341 6229 c-32 -30 -41 -61 -41 -139 0 -109 31 -160 100 -160 72 0
            110 55 110 160 0 70 -18 120 -51 144 -33 23 -90 21 -118 -5z m98 -28 c11 -11
            23 -40 26 -65 19 -126 -42 -220 -101 -155 -16 17 -19 37 -19 109 0 72 3 92 19
            109 24 26 50 27 75 2z"
            />
            <path
              d="M6625 6241 c-21 -4 -31 -13 -33 -28 -3 -19 0 -21 32 -13 l36 8 0
            -119 0 -119 -36 0 c-28 0 -35 -3 -32 -17 3 -15 17 -18 91 -21 82 -3 87 -2 87
            18 0 16 -6 20 -35 20 l-35 0 0 140 c0 156 5 148 -75 131z"
            />
            <path
              d="M6925 6245 c-5 -2 -22 -6 -37 -9 -19 -4 -28 -13 -28 -26 0 -11 5 -18
            13 -16 6 3 24 7 40 10 l27 6 0 -120 0 -120 -35 0 c-29 0 -35 -4 -35 -20 0 -20
            5 -21 87 -18 74 3 88 6 91 21 3 14 -4 17 -32 17 l-36 0 0 140 c0 139 0 140
            -22 139 -13 0 -27 -2 -33 -4z"
            />
            <path
              d="M7172 6240 c-40 -9 -46 -15 -39 -34 5 -12 13 -13 39 -5 18 5 34 9 35
            9 2 0 3 -54 3 -120 l0 -120 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20
            83 0 90 1 90 20 0 16 -7 20 -35 20 l-35 0 0 140 c0 157 4 150 -78 130z"
            />
            <path
              d="M7441 6234 c-33 -24 -51 -74 -51 -144 0 -105 38 -160 110 -160 68 0
            102 53 102 160 0 74 -11 109 -43 139 -28 26 -85 28 -118 5z m95 -35 c16 -17
            19 -37 19 -109 0 -72 -3 -92 -19 -109 -40 -44 -90 -13 -101 63 -19 126 42 220
            101 155z"
            />
            <path
              d="M7745 6245 c-5 -2 -22 -6 -37 -9 -19 -4 -28 -13 -28 -26 0 -11 5 -18
            13 -16 6 3 24 7 40 10 l27 6 0 -120 0 -120 -35 0 c-28 0 -35 -4 -35 -20 0 -19
            7 -20 90 -20 83 0 90 1 90 20 0 16 -7 20 -35 20 l-35 0 0 140 c0 139 0 140
            -22 139 -13 0 -27 -2 -33 -4z"
            />
            <path
              d="M7992 6240 c-40 -9 -46 -15 -39 -34 5 -12 13 -13 39 -5 18 5 34 9 35
            9 2 0 3 -54 3 -120 l0 -120 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90 -20
            83 0 90 1 90 20 0 16 -7 20 -35 20 l-35 0 0 140 c0 157 4 150 -78 130z"
            />
            <path
              d="M8257 6230 c-30 -24 -47 -75 -47 -140 0 -103 39 -160 110 -160 66 0
            104 59 104 160 0 103 -37 160 -102 160 -24 0 -49 -8 -65 -20z m102 -36 c17
            -22 21 -41 21 -104 0 -63 -4 -82 -21 -104 -40 -50 -93 -21 -104 58 -20 130 46
            224 104 150z"
            />
            <path
              d="M8537 6239 c-34 -20 -51 -70 -51 -149 0 -112 31 -160 104 -160 46 0
            69 16 91 63 36 80 14 218 -39 246 -26 14 -81 14 -105 0z m92 -38 c10 -10 21
            -39 25 -65 20 -130 -45 -224 -103 -150 -17 22 -21 41 -21 104 0 63 4 82 21
            104 24 31 52 33 78 7z"
            />
            <path
              d="M8803 6228 c-35 -39 -43 -64 -43 -138 0 -105 36 -160 105 -160 67 0
            105 58 105 160 0 105 -38 160 -110 160 -25 0 -43 -7 -57 -22z m109 -48 c35
            -69 15 -200 -32 -215 -55 -18 -89 53 -78 166 9 91 75 120 110 49z"
            />
            <path
              d="M9085 6241 c-20 -4 -31 -13 -33 -28 -3 -19 0 -21 32 -13 l36 8 0
            -119 0 -119 -36 0 c-28 0 -35 -3 -32 -17 3 -16 15 -18 93 -18 78 0 90 2 93 18
            3 14 -4 17 -32 17 l-35 0 -3 138 -3 137 -25 2 c-14 0 -38 -2 -55 -6z"
            />
            <path
              d="M9357 6239 c-34 -20 -51 -70 -51 -149 0 -113 31 -160 107 -160 68 0
            107 59 107 160 0 103 -39 160 -109 160 -20 0 -44 -5 -54 -11z m92 -38 c10 -10
            21 -39 25 -65 20 -130 -45 -224 -103 -150 -17 22 -21 41 -21 104 0 63 4 82 21
            104 24 31 52 33 78 7z"
            />
            <path
              d="M9635 6241 c-21 -4 -31 -13 -33 -28 -3 -20 -1 -21 30 -12 18 5 34 9
            35 9 2 0 3 -54 3 -120 l0 -120 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90
            -20 83 0 90 1 90 20 0 16 -7 20 -35 20 l-35 0 0 140 c0 156 5 148 -75 131z"
            />
            <path
              d="M9897 6230 c-30 -24 -47 -75 -47 -140 0 -101 39 -160 107 -160 76 0
            107 47 107 160 0 80 -18 131 -53 150 -32 16 -86 12 -114 -10z m102 -36 c17
            -22 21 -41 21 -104 0 -63 -4 -82 -21 -104 -58 -74 -123 20 -103 150 12 80 63
            108 103 58z"
            />
            <path
              d="M10166 6226 c-35 -34 -51 -120 -36 -192 15 -73 45 -104 98 -104 73 0
            112 56 112 160 0 104 -39 160 -112 160 -28 0 -46 -7 -62 -24z m103 -25 c11
            -11 23 -40 26 -65 20 -130 -46 -224 -104 -150 -17 22 -21 41 -21 104 0 63 4
            82 21 104 24 31 52 33 78 7z"
            />
            <path
              d="M10451 6234 c-33 -24 -51 -74 -51 -144 0 -102 38 -160 105 -160 69 0
            105 55 105 160 0 74 -8 99 -43 138 -25 27 -81 30 -116 6z m95 -35 c16 -17 19
            -37 19 -110 0 -77 -3 -91 -22 -110 -49 -50 -103 8 -103 110 0 108 56 165 106
            110z"
            />
            <path
              d="M10718 6231 c-28 -23 -48 -80 -48 -141 0 -99 41 -160 107 -160 76 0
            107 47 107 160 0 80 -18 131 -53 150 -31 16 -86 12 -113 -9z m88 -19 c24 -15
            34 -54 34 -126 0 -64 -3 -79 -23 -102 -61 -70 -129 45 -98 163 17 62 52 89 87
            65z"
            />
            <path
              d="M10985 6224 c-53 -58 -52 -214 2 -268 32 -33 89 -35 126 -6 63 50 63
            230 0 280 -38 30 -97 27 -128 -6z m104 -23 c11 -11 23 -40 26 -65 19 -126 -42
            -220 -101 -155 -16 17 -19 37 -19 109 0 72 3 92 19 109 24 26 50 27 75 2z"
            />
            <path
              d="M11271 6234 c-33 -24 -51 -74 -51 -144 0 -105 38 -160 110 -160 27 0
            44 8 66 31 28 29 29 33 29 129 0 96 -1 100 -29 129 -34 36 -86 42 -125 15z
            m95 -35 c16 -17 19 -37 19 -110 0 -77 -3 -91 -22 -110 -49 -50 -103 8 -103
            110 0 108 56 165 106 110z"
            />
            <path
              d="M11535 6226 c-26 -26 -45 -83 -45 -136 0 -97 43 -160 110 -160 67 0
            110 63 110 160 0 97 -43 160 -110 160 -30 0 -47 -6 -65 -24z m91 -14 c48 -32
            52 -188 6 -234 -28 -29 -66 -18 -83 24 -8 18 -14 60 -13 93 1 79 24 125 64
            125 8 0 20 -4 26 -8z"
            />
            <path
              d="M11822 6240 c-40 -9 -46 -15 -39 -34 5 -12 13 -13 39 -5 18 5 34 9
            35 9 2 0 3 -54 3 -120 l0 -120 -35 0 c-28 0 -35 -4 -35 -20 0 -19 7 -20 90
            -20 83 0 90 1 90 20 0 16 -7 20 -35 20 l-35 0 0 140 c0 157 4 150 -78 130z"
            />
            <path
              d="M12095 6241 c-21 -4 -31 -13 -33 -28 -3 -19 0 -21 32 -13 l36 8 0
            -119 0 -119 -36 0 c-28 0 -35 -3 -32 -17 3 -15 17 -18 91 -21 82 -3 87 -2 87
            18 0 16 -6 20 -35 20 l-35 0 0 140 c0 156 5 148 -75 131z"
            />
            <path
              d="M12395 6245 c-5 -2 -22 -6 -37 -9 -19 -4 -28 -13 -28 -26 0 -11 5
            -18 13 -16 6 3 24 7 40 10 l27 6 0 -120 0 -120 -35 0 c-29 0 -35 -4 -35 -20 0
            -20 5 -21 87 -18 74 3 88 6 91 21 3 14 -4 17 -32 17 l-36 0 0 140 c0 139 0
            140 -22 139 -13 0 -27 -2 -33 -4z"
            />
            <path
              d="M12631 6229 c-32 -30 -41 -61 -41 -139 0 -109 31 -160 100 -160 72 0
      110 55 110 160 0 70 -18 120 -51 144 -33 23 -90 21 -118 -5z m89 -19 c39 -21
      54 -138 26 -205 -19 -45 -59 -57 -89 -26 -19 19 -22 33 -22 110 0 73 3 93 19
      110 21 23 38 26 66 11z"
            />
          </g>
        </g>
      </pattern>
      <mask id="codeMask">
        <rect width="100%" height="100%" fill="url(#codePattern)" class="duration-1000 ease-out" :class="{ 'opacity-20': !activated }" />
      </mask>
      <defs>
        <linearGradient id="blackGradient" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0" stop-color="red">
            <animate
              attributeName="stop-color"
              values="black;##171717;##262626" dur="10s" repeatCount="indefinite"
            />
          </stop>
          <stop offset=".5" stop-color="purple">
            <animate
              attributeName="stop-color"
              values="##262626;#171717;black;" dur="10s" repeatCount="indefinite"
            />
          </stop>
          <stop offset="1" stop-color="blue">
            <animate
              attributeName="stop-color"
              values="black;##171717;##262626;" dur="10s" repeatCount="indefinite"
            />
          </stop>
          <animateTransform
            attributeName="gradientTransform" type="rotate" from="0 .5 .5" to="360 .5 .5"
            dur="10s" repeatCount="indefinite"
          />
        </linearGradient>
        <linearGradient id="a" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0" stop-color="red">
            <animate
              attributeName="stop-color"
              values="red;purple;blue;green;yellow;orange;red;" dur="10s" repeatCount="indefinite"
            />
          </stop>
          <stop offset=".5" stop-color="purple">
            <animate
              attributeName="stop-color"
              values="purple;blue;green;yellow;orange;red;purple;" dur="10s" repeatCount="indefinite"
            />
          </stop>
          <stop offset="1" stop-color="blue">
            <animate
              attributeName="stop-color"
              values="blue;green;yellow;orange;red;purple;blue;" dur="10s" repeatCount="indefinite"
            />
          </stop>
          <animateTransform
            attributeName="gradientTransform" type="rotate" from="0 .5 .5" to="360 .5 .5"
            dur="10s" repeatCount="indefinite"
          />
        </linearGradient>
        <linearGradient id="b" gradientUnits="objectBoundingBox" x1="0" y1="1" x2="1" y2="1">
          <stop offset="0" stop-color="red">
            <animate
              attributeName="stop-color"
              values="red;purple;blue;green;yellow;orange;red;" dur="10s" repeatCount="indefinite"
            />
          </stop>
          <stop offset="1" stop-color="purple" stop-opacity="0">
            <animate
              attributeName="stop-color"
              values="purple;blue;green;yellow;orange;red;purple;" dur="10s" repeatCount="indefinite"
            />
          </stop>
          <animateTransform
            attributeName="gradientTransform" type="rotate" values="360 .5 .5;0 .5 .5" class="ignore"
            dur="10s" repeatCount="indefinite"
          />
        </linearGradient>
      </defs>
      <g mask="url(#codeMask)">
        <g>
          <rect fill="url(#a)" width="100%" height="100%" />
          <rect fill="url(#b)" width="100%" height="100%" />
        </g>
        <transition name="fade" mode="out-in">
          <rect v-if="!activated" fill="url(#blackGradient)" width="100%" height="100%" class="absolute inset-0" />
        </transition>
      </g>
    </svg>
    <div class="absolute inset-0 z-10 w-full h-full">
      <svg viewBox="0 0 640 480">
        <mask id="vignetteMask">
          <rect fill="url(#radialGrad)" width="100%" height="100%" />
        </mask>
        <defs>
          <radialGradient id="radialGrad" cx="50%" cy="40%" r="100%">
            <stop offset="0%" stop-color="black" />
            <stop offset="50%" stop-color="white" />
          </radialGradient>
        </defs>
        <g mask="url(#vignetteMask)">
          <rect fill="#0a0a0a" width="100%" height="100%" />
        </g>
      </svg>
    </div>
  </div>
</template>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
